import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/types";
import { ImportCSVResponseDTO } from "../database/dto";
import {
  ContactStateEnum,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  TargetListDTO,
  TargetListPageEnum,
} from "./dto";
import { NewTargetListsService } from "./service";

interface TargetListState {
  newTargetListRequest: NewTargetListRequest;
  newTargetListStatus: PromiseStatuses;
  selectedTargetList?: string;
  findTargetListStatus: PromiseStatuses;
  findTargetListResponse?: TargetListDTO;
  targetListPage: TargetListPageEnum;
  importPersonStatus: PromiseStatuses;
  importPersonResponse?: ImportCSVResponseDTO;
  importCompanyStatus: PromiseStatuses;
  importCompanyResponse?: ImportCSVResponseDTO;
  findAllTargetListsResponse?: FindAllTargetListsResponse;
  findAllTargetListsStatus: PromiseStatuses;
  filters: FindAllTargetListsFilters;
  deleteTargetListStatus: PromiseStatuses;
  removeContactsStatus: PromiseStatuses;
  addContactsStatus: PromiseStatuses;
  changeContactStateStatus: PromiseStatuses;
  validateNewTargetList: {
    errors: {
      name: boolean;
      departments: boolean;
      roles: boolean;
      sectors: boolean;
      categories: boolean;
      commercials: boolean;
      revenueSign: boolean;
      revenueFirst: boolean;
      revenueSecond: boolean;
      employeesSign: boolean;
      employeesFirst: boolean;
      employeesSecond: boolean;
    };
    status: PromiseStatuses;
  };
}

const initialState: TargetListState = {
  newTargetListRequest: {
    projectId: "",
    name: "",
    commercialIds: [],
    departments: [],
    roles: [],
    revenue: "undefined:undefined:undefined",
    employeesNumber: "undefined:undefined:undefined",
    sectors: [],
    categories: [],
  },
  newTargetListStatus: "idle",
  validateNewTargetList: {
    errors: {
      name: false,
      departments: false,
      roles: false,
      sectors: false,
      categories: false,
      commercials: false,
      revenueSign: false,
      revenueFirst: false,
      revenueSecond: false,
      employeesSign: false,
      employeesFirst: false,
      employeesSecond: false,
    },
    status: "idle",
  },
  findTargetListStatus: "idle",
  targetListPage: TargetListPageEnum.LIST,
  importPersonStatus: "idle",
  importCompanyStatus: "idle",
  findAllTargetListsStatus: "idle",
  filters: {
    commercial: [],
    contacts: "",
    itemsPerPage: 15,
    name: "",
    order: true,
    page: 0,
    sector: [],
    sort: "name",
    state: [],
    teamLeader: [],
  },
  deleteTargetListStatus: "idle",
  removeContactsStatus: "idle",
  addContactsStatus: "idle",
  changeContactStateStatus: "idle",
};

export const newTargetList = createAsyncThunk(
  "targetLists/newTargetList",
  async (data: NewTargetListRequest, thunkApi): Promise<string> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.createTargetList(data);
  }
);

export const changeContactState = createAsyncThunk(
  "targetLists/changeContactState",
  async (
    request: {
      targetListId: string;
      type: "persons" | "companies";
      contactState: ContactStateEnum;
      contactId: string;
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.changeContactStatus(
      request.targetListId,
      request.type,
      request.contactState,
      request.contactId
    );
  }
);

export const removeContacts = createAsyncThunk(
  "targetLists/removeContacts",
  async (
    data: {
      targetListId: string;
      type: "persons" | "companies";
      contactsId: string[];
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.removeContacts(
      data.targetListId,
      data.type,
      data.contactsId
    );
  }
);

export const addContacts = createAsyncThunk(
  "targetLists/addContacts",
  async (
    data: {
      targetListId: string;
      type: "persons" | "companies";
      contactsId: string[];
    },
    thunkApi
  ): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.addContacts(
      data.targetListId,
      data.type,
      data.contactsId
    );
  }
);

export const findAllTArgetLists = createAsyncThunk(
  "targetLists/findAllTArgetLists",
  async (
    filters: FindAllTargetListsFilters,
    thunkApi
  ): Promise<FindAllTargetListsResponse> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.findAllTargetLists(filters);
  }
);

export const importContactPersonCSV = createAsyncThunk(
  "targetLists/importContactPersonCSV",
  async (
    request: { data: FormData; id: string },
    thunkApi
  ): Promise<ImportCSVResponseDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.importContactCSV(
      request.data,
      "persons",
      request.id
    );
  }
);

export const importContactCompanyCSV = createAsyncThunk(
  "targetLists/importContactCompanyCSV",
  async (
    request: { data: FormData; id: string },
    thunkApi
  ): Promise<ImportCSVResponseDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.importContactCSV(
      request.data,
      "companies",
      request.id
    );
  }
);

export const findTargetListById = createAsyncThunk(
  "targetLists/findTargetListById",
  async (id: string, thunkApi): Promise<TargetListDTO> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.findTargetListById(id);
  }
);

export const deleteTargetList = createAsyncThunk(
  "targetLists/deleteTargetList",
  async (id: string, thunkApi): Promise<void> => {
    const TargetListsService = NewTargetListsService();

    return TargetListsService.deleteTargetList(id);
  }
);

export const validateNewTargetList = createAsyncThunk(
  "targetLists/validateNewTargetList",
  async (targetList: NewTargetListRequest, thunkApi): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateNewTargetListCategories(false));
    thunkApi.dispatch(setValidateNewTargetListCommercials(false));
    thunkApi.dispatch(setValidateNewTargetListDepartments(false));
    thunkApi.dispatch(setValidateNewTargetListEmployeesFirst(false));
    thunkApi.dispatch(setValidateNewTargetListEmployeesSecond(false));
    thunkApi.dispatch(setValidateNewTargetListEmployeesSign(false));
    thunkApi.dispatch(setValidateNewTargetListName(false));
    thunkApi.dispatch(setValidateNewTargetListRevenueFirst(false));
    thunkApi.dispatch(setValidateNewTargetListRevenueSecond(false));
    thunkApi.dispatch(setValidateNewTargetListRevenueSign(false));
    thunkApi.dispatch(setValidateNewTargetListRoles(false));
    thunkApi.dispatch(setValidateNewTargetListSectors(false));
    thunkApi.dispatch(setValidateNewTargetListStatus("idle"));

    if (targetList.categories.length === 0 && targetList.sectors.length > 0) {
      thunkApi.dispatch(setValidateNewTargetListCategories(true));
      isValid = false;
    }

    if (targetList.commercialIds.length === 0) {
      thunkApi.dispatch(setValidateNewTargetListCommercials(true));
      isValid = false;
    }

    if (targetList.departments.length === 0) {
      thunkApi.dispatch(setValidateNewTargetListDepartments(true));
      isValid = false;
    }

    if (targetList.employeesNumber.split(":")[0] === "undefined") {
      thunkApi.dispatch(setValidateNewTargetListEmployeesSign(true));
      isValid = false;
    }

    if (
      targetList.employeesNumber.split(":")[0] !== "undefined" &&
      targetList.employeesNumber.split(":")[1] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListEmployeesFirst(true));
      isValid = false;
    }

    if (
      targetList.employeesNumber.split(":")[0] === "><" &&
      targetList.employeesNumber.split(":")[2] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListEmployeesSecond(true));
      isValid = false;
    }

    if (targetList.name === "" || !targetList.name) {
      thunkApi.dispatch(setValidateNewTargetListName(true));
      isValid = false;
    }

    if (targetList.revenue.split(":")[0] === "undefined") {
      thunkApi.dispatch(setValidateNewTargetListRevenueSign(true));
      isValid = false;
    }

    if (
      targetList.revenue.split(":")[0] !== "undefined" &&
      targetList.revenue.split(":")[1] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListRevenueFirst(true));
      isValid = false;
    }

    if (
      targetList.revenue.split(":")[0] === "><" &&
      targetList.revenue.split(":")[2] === "undefined"
    ) {
      thunkApi.dispatch(setValidateNewTargetListRevenueSecond(true));
      isValid = false;
    }

    if (targetList.roles.length === 0 && targetList.departments.length > 0) {
      thunkApi.dispatch(setValidateNewTargetListRoles(true));
      isValid = false;
    }

    if (targetList.sectors.length === 0) {
      thunkApi.dispatch(setValidateNewTargetListSectors(true));
      isValid = false;
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

const targetListSlice = createSlice({
  name: "targetList/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(validateNewTargetList.pending, (state) => {
        state.validateNewTargetList.status = "loading";
      })
      .addCase(validateNewTargetList.fulfilled, (state) => {
        state.validateNewTargetList.status = "successfully";
      })
      .addCase(validateNewTargetList.rejected, (state) => {
        state.validateNewTargetList.status = "failed";
      })
      .addCase(newTargetList.pending, (state) => {
        state.newTargetListStatus = "loading";
      })
      .addCase(newTargetList.fulfilled, (state, action) => {
        state.newTargetListStatus = "successfully";
        state.selectedTargetList = action.payload;
      })
      .addCase(newTargetList.rejected, (state) => {
        state.newTargetListStatus = "failed";
      })
      .addCase(findTargetListById.pending, (state) => {
        state.findTargetListStatus = "loading";
      })
      .addCase(findTargetListById.fulfilled, (state, action) => {
        state.findTargetListStatus = "successfully";
        state.findTargetListResponse = action.payload;
      })
      .addCase(findTargetListById.rejected, (state) => {
        state.findTargetListStatus = "failed";
      })
      .addCase(importContactPersonCSV.pending, (state) => {
        state.importPersonStatus = "loading";
      })
      .addCase(importContactPersonCSV.fulfilled, (state, action) => {
        state.importPersonStatus = "successfully";
        state.importPersonResponse = action.payload;
      })
      .addCase(importContactPersonCSV.rejected, (state) => {
        state.importPersonStatus = "failed";
      })
      .addCase(importContactCompanyCSV.pending, (state) => {
        state.importCompanyStatus = "loading";
      })
      .addCase(importContactCompanyCSV.fulfilled, (state, action) => {
        state.importCompanyStatus = "successfully";
        state.importCompanyResponse = action.payload;
      })
      .addCase(importContactCompanyCSV.rejected, (state) => {
        state.importCompanyStatus = "failed";
      })
      .addCase(findAllTArgetLists.pending, (state) => {
        state.findAllTargetListsStatus = "loading";
      })
      .addCase(findAllTArgetLists.fulfilled, (state, action) => {
        state.findAllTargetListsStatus = "successfully";
        state.findAllTargetListsResponse = action.payload;
      })
      .addCase(findAllTArgetLists.rejected, (state) => {
        state.findAllTargetListsStatus = "failed";
      })
      .addCase(deleteTargetList.pending, (state) => {
        state.deleteTargetListStatus = "loading";
      })
      .addCase(deleteTargetList.fulfilled, (state, action) => {
        state.deleteTargetListStatus = "successfully";
      })
      .addCase(deleteTargetList.rejected, (state) => {
        state.deleteTargetListStatus = "failed";
      })
      .addCase(removeContacts.pending, (state) => {
        state.removeContactsStatus = "loading";
      })
      .addCase(removeContacts.fulfilled, (state, action) => {
        state.removeContactsStatus = "successfully";
      })
      .addCase(removeContacts.rejected, (state) => {
        state.removeContactsStatus = "failed";
      })
      .addCase(addContacts.pending, (state) => {
        state.addContactsStatus = "loading";
      })
      .addCase(addContacts.fulfilled, (state, action) => {
        state.addContactsStatus = "successfully";
      })
      .addCase(addContacts.rejected, (state) => {
        state.addContactsStatus = "failed";
      })
      .addCase(changeContactState.pending, (state) => {
        state.changeContactStateStatus = "loading";
      })
      .addCase(changeContactState.fulfilled, (state, action) => {
        state.changeContactStateStatus = "successfully";
      })
      .addCase(changeContactState.rejected, (state) => {
        state.changeContactStateStatus = "failed";
      });
  },
  reducers: {
    setNewTargetListProjectId: (state, action) => {
      state.newTargetListRequest.projectId = action.payload;
    },
    setNewTargetListCategories: (state, action) => {
      state.newTargetListRequest.categories = action.payload;
    },
    setNewTargetListCommercialIds: (state, action) => {
      state.newTargetListRequest.commercialIds = action.payload;
    },
    setNewTargetListDepartments: (state, action) => {
      state.newTargetListRequest.departments = action.payload;
    },
    setNewTargetListEmployeesNumber: (state, action) => {
      state.newTargetListRequest.employeesNumber = action.payload;
    },
    setNewTargetListName: (state, action) => {
      state.newTargetListRequest.name = action.payload;
    },
    setNewTargetListRevenue: (state, action) => {
      state.newTargetListRequest.revenue = action.payload;
    },
    setNewTargetListRoles: (state, action) => {
      state.newTargetListRequest.roles = action.payload;
    },
    setNewTargetListSectors: (state, action) => {
      state.newTargetListRequest.sectors = action.payload;
    },
    setNewTargetListStatus: (state, action) => {
      state.newTargetListStatus = action.payload;
    },
    setValidateNewTargetListName: (state, action) => {
      state.validateNewTargetList.errors.name = action.payload;
    },
    setValidateNewTargetListCategories: (state, action) => {
      state.validateNewTargetList.errors.categories = action.payload;
    },
    setValidateNewTargetListCommercials: (state, action) => {
      state.validateNewTargetList.errors.commercials = action.payload;
    },
    setValidateNewTargetListDepartments: (state, action) => {
      state.validateNewTargetList.errors.departments = action.payload;
    },
    setValidateNewTargetListEmployeesFirst: (state, action) => {
      state.validateNewTargetList.errors.employeesFirst = action.payload;
    },
    setValidateNewTargetListEmployeesSecond: (state, action) => {
      state.validateNewTargetList.errors.employeesSecond = action.payload;
    },
    setValidateNewTargetListEmployeesSign: (state, action) => {
      state.validateNewTargetList.errors.employeesSign = action.payload;
    },
    setValidateNewTargetListRevenueFirst: (state, action) => {
      state.validateNewTargetList.errors.revenueFirst = action.payload;
    },
    setValidateNewTargetListRevenueSecond: (state, action) => {
      state.validateNewTargetList.errors.revenueSecond = action.payload;
    },
    setValidateNewTargetListRevenueSign: (state, action) => {
      state.validateNewTargetList.errors.revenueSign = action.payload;
    },
    setValidateNewTargetListRoles: (state, action) => {
      state.validateNewTargetList.errors.roles = action.payload;
    },
    setValidateNewTargetListSectors: (state, action) => {
      state.validateNewTargetList.errors.sectors = action.payload;
    },
    setValidateNewTargetListStatus: (state, action) => {
      state.validateNewTargetList.status = action.payload;
    },
    setSelectedTargetList: (state, action) => {
      state.selectedTargetList = action.payload;
    },
    setFindTargetListStatus: (state, action) => {
      state.findTargetListStatus = action.payload;
    },
    setTargetListPage: (state, action) => {
      state.targetListPage = action.payload;
    },
    setImportPersonCSVStatus: (state, action) => {
      state.importPersonStatus = action.payload;
    },
    setImportCompanyCSVStatus: (state, action) => {
      state.importCompanyStatus = action.payload;
    },
    setImportPersonCSVResponse: (state, action) => {
      state.importPersonResponse = action.payload;
    },
    setImportCompanyCSVResponse: (state, action) => {
      state.importCompanyResponse = action.payload;
    },
    setFindAllTargetListsStatus: (state, action) => {
      state.findAllTargetListsStatus = action.payload;
    },
    setTargetListsFilterPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setTargetListsFilterSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setTargetListsFilterItemsPerPage: (state, action) => {
      state.filters.itemsPerPage = action.payload;
    },
    setTargetListsFilterOrder: (state, action) => {
      state.filters.order = action.payload;
    },
    setDeleteTargetListStatus: (state, action) => {
      state.deleteTargetListStatus = action.payload;
    },
    setTargetListFilterName: (state, action) => {
      state.filters.name = action.payload;
    },
    setTargetListFilterTeamLeader: (state, action) => {
      state.filters.teamLeader = action.payload;
    },
    setTargetListFilterCommercial: (state, action) => {
      state.filters.commercial = action.payload;
    },
    setTargetListFilterSector: (state, action) => {
      state.filters.sector = action.payload;
    },
    setTargetListFilterState: (state, action) => {
      state.filters.state = action.payload;
    },
    setRemoveContactsStatus: (state, action) => {
      state.removeContactsStatus = action.payload;
    },
    setAddContactsStatus: (state, action) => {
      state.addContactsStatus = action.payload;
    },
    setChangeContactStateStatus: (state, action) => {
      state.changeContactStateStatus = action.payload;
    },
  },
});

export const {
  setNewTargetListCategories,
  setNewTargetListCommercialIds,
  setNewTargetListDepartments,
  setNewTargetListEmployeesNumber,
  setNewTargetListName,
  setNewTargetListProjectId,
  setNewTargetListRevenue,
  setNewTargetListRoles,
  setNewTargetListSectors,
  setNewTargetListStatus,
  setValidateNewTargetListCategories,
  setValidateNewTargetListCommercials,
  setValidateNewTargetListDepartments,
  setValidateNewTargetListEmployeesFirst,
  setValidateNewTargetListEmployeesSecond,
  setValidateNewTargetListEmployeesSign,
  setValidateNewTargetListName,
  setValidateNewTargetListRevenueFirst,
  setValidateNewTargetListRevenueSecond,
  setValidateNewTargetListRevenueSign,
  setValidateNewTargetListRoles,
  setValidateNewTargetListSectors,
  setValidateNewTargetListStatus,
  setSelectedTargetList,
  setFindTargetListStatus,
  setTargetListPage,
  setImportPersonCSVResponse,
  setImportPersonCSVStatus,
  setFindAllTargetListsStatus,
  setTargetListsFilterItemsPerPage,
  setTargetListsFilterOrder,
  setTargetListsFilterPage,
  setTargetListsFilterSort,
  setDeleteTargetListStatus,
  setTargetListFilterName,
  setTargetListFilterTeamLeader,
  setTargetListFilterCommercial,
  setTargetListFilterSector,
  setTargetListFilterState,
  setRemoveContactsStatus,
  setAddContactsStatus,
  setImportCompanyCSVStatus,
  setImportCompanyCSVResponse,
  setChangeContactStateStatus,
} = targetListSlice.actions;

export default targetListSlice.reducer;
