import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../lib/redux/hooks";
import { EditIcon } from "../../../../../../ui/icons/edit";
import { FiltersLinesIcon } from "../../../../../../ui/icons/filtersLines";
import { MoreHorizontalIcon } from "../../../../../../ui/icons/moreHorizontal";
import { TrashIcon } from "../../../../../../ui/icons/trash";
import Button from "../../../../../../ui/molecules/button";
import Checkbox from "../../../../../../ui/molecules/checkbox";
import Pills from "../../../../../../ui/molecules/pills";
import { ConfirmModal } from "../../../../../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../../../../../ui/organisms/dropdown";
import { Pagination } from "../../../../../../ui/organisms/pagination";
import { EditPerson } from "../../../../../database/people/editPerson";
import { OptionsPeople } from "../../../../../database/people/options/options";
import {
  setFindAllPeopleFiltersPage,
  setSelectedPerson,
} from "../../../../../database/people/slice";
import {
  setFiltersActive,
  setOptionsActive,
} from "../../../../../database/slice";
import {
  contactsStatePillMap,
  ContactStateEnum,
  TargetListPageEnum,
} from "../../../../../targetList/dto";
import {
  findTargetListById,
  removeContacts,
  setAddContactsStatus,
  setRemoveContactsStatus,
  setTargetListPage,
} from "../../../../../targetList/slice";
import { PeopleColumnMap } from "../columnMap";

export function TargetPeopleList() {
  const dispatch = useAppDispatch();
  const targetListState = useAppSelector((state) => state.targetList);
  const peopleState = useAppSelector((state) => state.people);
  const preferencesState = useAppSelector((state) => state.preferences);
  const databaseState = useAppSelector((state) => state.database);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (
      targetListState.removeContactsStatus === "failed" ||
      targetListState.removeContactsStatus === "successfully"
    ) {
      setShowPopup(false);
    }
  }, [targetListState.removeContactsStatus]);

  return (
    <div className="w-full">
      <div className="px-4 h-16 flex items-center justify-between border-b border-b-neutral-100">
        <div className="flex items-center gap-10">
          <span className="text-heading-xs font-semibold text-neutral-800">
            Persone
          </span>
          <div className="flex items-center gap-2 h-7">
            <div className="flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-green-400">
              <span className="text-green-800 text-label-sm font-medium">
                Lead preso
              </span>
              <div className="bg-green-100 flex items-center justify-center text-green-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                {
                  Object.values(
                    targetListState.findTargetListResponse?.personContacts || {}
                  ).filter(
                    (person) =>
                      peopleState.findAllResponse?.data
                        .map((_pers) => _pers.id)
                        .includes(person.contactId) &&
                      person.contactState === ContactStateEnum.LEADTAKEN
                  ).length
                }
              </div>
            </div>
            <div className="flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-warning-400">
              <span className="text-warning-800 text-label-sm font-medium">
                Da richiamare
              </span>
              <div className="bg-warning-100 flex items-center justify-center text-warning-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                {
                  Object.values(
                    targetListState.findTargetListResponse?.personContacts || {}
                  ).filter(
                    (person) =>
                      peopleState.findAllResponse?.data
                        .map((_pers) => _pers.id)
                        .includes(person.contactId) &&
                      person.contactState === ContactStateEnum.TORECALL
                  ).length
                }
              </div>
            </div>
            <div className="flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-red-400">
              <span className="text-red-800 text-label-sm font-medium">Ko</span>
              <div className="bg-red-100 flex items-center justify-center text-red-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                {
                  Object.values(
                    targetListState.findTargetListResponse?.personContacts || {}
                  ).filter(
                    (person) =>
                      peopleState.findAllResponse?.data
                        .map((_pers) => _pers.id)
                        .includes(person.contactId) &&
                      person.contactState === ContactStateEnum.KO
                  ).length
                }
              </div>
            </div>
            <div className="flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-neutral-400">
              <span className="text-neutral-800 text-label-sm font-medium">
                Info errate
              </span>
              <div className="bg-neutral-100 flex items-center justify-center text-neutral-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                {
                  Object.values(
                    targetListState.findTargetListResponse?.personContacts || {}
                  ).filter(
                    (person) =>
                      peopleState.findAllResponse?.data
                        .map((_pers) => _pers.id)
                        .includes(person.contactId) &&
                      person.contactState === ContactStateEnum.INCORRECT
                  ).length
                }
              </div>
            </div>
            <div className="flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-400">
              <span className="text-brandPrimary-800 text-label-sm font-medium">
                In attesa
              </span>
              <div className="bg-brandPrimary-100 flex items-center justify-center text-brandPrimary-700 h-5 w-5 rounded-2xl text-label-xs font-semibold text-center">
                {
                  Object.values(
                    targetListState.findTargetListResponse?.personContacts || {}
                  ).filter(
                    (person) =>
                      peopleState.findAllResponse?.data
                        .map((_pers) => _pers.id)
                        .includes(person.contactId) &&
                      person.contactState === ContactStateEnum.DRAFT
                  ).length
                }
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          {selectedIds.length > 0 && (
            <Button
              size={"sm"}
              iconPosition={"only"}
              variant={"outline"}
              color={"blue"}
              icon={<TrashIcon color={""} size={0} />}
              onClick={() => setShowPopup(true)}
            />
          )}
          <Button
            size={"sm"}
            iconPosition={"only"}
            variant={databaseState.optionsActive ? "outline" : "ghost"}
            color={databaseState.optionsActive ? "blue" : "gray"}
            icon={<MoreHorizontalIcon color={""} size={0} />}
            onClick={() =>
              dispatch(setOptionsActive(!databaseState.optionsActive))
            }
          />
          <Button
            size={"sm"}
            iconPosition={"only"}
            variant={databaseState.filtersActive ? "outline" : "ghost"}
            color={databaseState.filtersActive ? "blue" : "gray"}
            icon={<FiltersLinesIcon color={""} size={0} />}
            onClick={() =>
              dispatch(setFiltersActive(!databaseState.filtersActive))
            }
          />
        </div>
      </div>
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4 w-10">
                <Checkbox
                  shape={"square"}
                  checked={
                    selectedIds.length ===
                    peopleState.findAllResponse?.data.length
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      selectedIds.length ===
                      peopleState.findAllResponse?.data.length
                    ) {
                      setSelectedIds([]);
                    } else {
                      setSelectedIds(
                        peopleState.findAllResponse?.data?.map(
                          (person) => person.id
                        )!
                      );
                    }
                  }}
                />
              </th>
              {preferencesState.findAllResponse?.data[0].personPreferences
                .filter((column) => column.visible)
                .map((column) => (
                  <th key={column.column} className="text-left">
                    <div className="flex gap-[4px] px-[4px] py-3">
                      {column.column}
                    </div>
                  </th>
                ))}
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3 min-w-[100px]">
                  esito
                </div>
              </th>
              <th className="text-left pr-4 pl-[4px]">
                <div className="flex gap-[4px] px-[4px] w-[20px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {peopleState.findAllResponse?.data.map((person) => (
              <tr
                className={clsx(
                  "h-[56px] border-b-[1px] border-b-neutral-100",
                  {
                    "bg-brandPrimary-100": selectedIds.includes(person.id),
                    "bg-white": !selectedIds.includes(person.id),
                  }
                )}
                key={person.id}
              >
                <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                  <Checkbox
                    shape={"square"}
                    checked={selectedIds.includes(person.id)}
                    onCheck={function (value: boolean): void {
                      if (selectedIds.includes(person.id)) {
                        setSelectedIds([
                          ...selectedIds.filter((id) => id !== person.id),
                        ]);
                      } else {
                        setSelectedIds([...selectedIds, person.id]);
                      }
                    }}
                  />
                </td>
                {preferencesState.findAllResponse?.data[0].personPreferences
                  .filter((column) => column.visible)
                  .map((column, index) => (
                    <PeopleColumnMap
                      key={person.id + "-" + column.column}
                      person={person}
                      column={column.column}
                    />
                  ))}
                <td className="px-[4px] text-text-md font-semibold text-neutral-600">
                  <Pills
                    size={"sm"}
                    label={
                      targetListState.findTargetListResponse?.personContacts
                        ? contactsStatePillMap.get(
                            targetListState.findTargetListResponse
                              ?.personContacts[person.id]?.contactState!
                          )?.label!
                        : ""
                    }
                    outline={false}
                    emphasis={false}
                    color={
                      targetListState.findTargetListResponse?.personContacts
                        ? contactsStatePillMap.get(
                            targetListState.findTargetListResponse
                              ?.personContacts[person.id]?.contactState!
                          )?.color!
                        : "blue"
                    }
                  />
                </td>

                <td className="w-[20px] cursor-pointer">
                  <Dropdown
                    items={[
                      {
                        label: "Modifica",
                        icon: <EditIcon color={""} size={0} />,
                        onClick: () => dispatch(setSelectedPerson(person.id)),
                      },
                      {
                        label: "Cancella",
                        icon: <TrashIcon color={""} size={0} />,
                        onClick: () => {
                          setSelectedIds([person.id]);
                          setShowPopup(true);
                        },
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        found={peopleState.findAllResponse?.total || 0}
        key={"pagination-people-bottom"}
        pages={peopleState.findAllResponse?.totalPage || 0}
        currentPage={peopleState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllPeopleFiltersPage(page));
        }}
      />
      <div
        style={{ height: "calc(100% - 144px)" }}
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[290px] transition-all",
          {
            visible: databaseState.optionsActive,
            hidden: !databaseState.optionsActive,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <OptionsPeople />
        </div>
      </div>
      <div
        style={{ height: "calc(100% - 144px)" }}
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
          {
            hidden: peopleState.selectedPerson === undefined,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <EditPerson />
        </div>
      </div>
      <ConfirmModal
        loading={targetListState.removeContactsStatus === "loading"}
        title={"Rimuovi persone dalla lista"}
        label={
          "Sei sicuro di voler eliminare le persone selezionate dalla lista?"
        }
        confirmAction={function (): void {
          dispatch(
            removeContacts({
              targetListId: targetListState.findTargetListResponse?.id!,
              type: "persons",
              contactsId: selectedIds,
            })
          );
        }}
        onClose={function (): void {
          setSelectedIds([]);
          setShowPopup(false);
        }}
        visible={showPopup}
      />
    </div>
  );
}
