import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { Banner } from "../../../../ui/organisms/banner";
import { NotFound } from "../../../../ui/organisms/notFound";
import {
  setDeleteTargetListStatus,
  setNewTargetListProjectId,
} from "../../../targetList/slice";
import { EditProjectPage } from "../../dto";
import { setEditProjectPage } from "../../slice";
import { TargetListTable } from "./list";

export function TargetLists() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col bg-white rounded-2xl shadow-sm m-6">
      <div className="absolute flex flex-col gap-4 right-6">
        <Banner
          label={"Lista target eliminata."}
          visible={targetListsState.deleteTargetListStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setDeleteTargetListStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante l'eliminazione della lista target."
          }
          visible={targetListsState.deleteTargetListStatus === "failed"}
          closeAction={function (): void {
            dispatch(setDeleteTargetListStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <div className="p-4 flex justify-between items-center">
        <span className="text-heading-xs font-semibold text-neutral-800">
          Liste Target del progetto
        </span>
        <Button
          size={"sm"}
          iconPosition={"off"}
          variant={"outline"}
          color={"blue"}
          label="Aggiungi lista target"
          onClick={() => {
            dispatch(setEditProjectPage(EditProjectPage.ADDLIST));
            dispatch(
              setNewTargetListProjectId(projectsState.findProjectResponse?.id)
            );
          }}
        />
      </div>
      {projectsState.findProjectStatus === "loading" ? (
        <div className="w-full h-[50%] flex items-center justify-center">
          <Spinner />
        </div>
      ) : projectsState.findProjectResponse === undefined ||
        !projectsState.findProjectResponse?.targetLists ||
        projectsState.findProjectResponse?.targetLists?.length === 0 ? (
        <NotFound />
      ) : (
        <TargetListTable />
      )}
    </div>
  );
}
