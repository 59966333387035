import clsx from "clsx";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../lib/redux/hooks";
import { colors } from "../../../../../../ui/colors";
import { CheckCircleIcon } from "../../../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../../../ui/icons/circle";
import { GlobeIcon } from "../../../../../../ui/icons/globe";
import { LinkedinIcon } from "../../../../../../ui/icons/linkedin";
import Checkbox from "../../../../../../ui/molecules/checkbox";
import { Pagination } from "../../../../../../ui/organisms/pagination";
import { setFindAllCompaniesFiltersPage } from "../../../../../database/companies/slice";

interface Props {
  selectedIds: string[];
  setSelectedIds: (values: string[]) => void;
  setPage: (page: number) => void;
}
export function ImportFromDatabaseList(props: Props) {
  const { selectedIds, setSelectedIds, setPage } = props;
  const targetListState = useAppSelector((state) => state.targetList);
  const projectsState = useAppSelector((state) => state.projects);

  const dispatch = useAppDispatch();

  const isAlreadyIn = (id: string): boolean => {
    return targetListState.findTargetListResponse?.companyContacts &&
      targetListState.findTargetListResponse?.companyContacts[id] !== undefined
      ? true
      : false;
  };

  return (
    <div className="w-full">
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4 w-10">
                <Checkbox
                  shape={"square"}
                  disabled={
                    projectsState.findAllCompaniesFromTargetResponse?.data.every(
                      (company) => isAlreadyIn(company.id)
                    )!
                  }
                  checked={
                    projectsState.findAllCompaniesFromTargetResponse?.data
                      .filter((company) => !isAlreadyIn(company.id))
                      .every((company) => selectedIds.includes(company.id))! ||
                    projectsState.findAllCompaniesFromTargetResponse?.data.every(
                      (company) => isAlreadyIn(company.id)
                    )!
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      projectsState.findAllCompaniesFromTargetResponse?.data
                        .filter((company) => !isAlreadyIn(company.id))
                        .every((company) => selectedIds.includes(company.id))
                    ) {
                      let newSelected = [...selectedIds];
                      projectsState.findAllCompaniesFromTargetResponse?.data.forEach(
                        (company) => {
                          newSelected = newSelected.filter(
                            (id) => company.id !== id
                          );
                        }
                      );
                      setSelectedIds([...newSelected]);
                    } else {
                      setSelectedIds([
                        ...selectedIds,
                        ...projectsState.findAllCompaniesFromTargetResponse?.data
                          .filter(
                            (company) =>
                              !selectedIds.includes(company.id) &&
                              !isAlreadyIn(company.id)
                          )
                          .map((company) => company.id)!,
                      ]);
                    }
                  }}
                />
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">email</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">telefono</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">indirizzo</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">cap</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">città</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">provincia</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">regione</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">stato</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">fatturato</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">P. IVA</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">ATECO</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">settore</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">categoria</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  <GlobeIcon color={colors.neutral[400]} size={16} />
                </div>
              </th>
              <th className="text-left  pr-4">
                <div className="flex gap-[4px] px-[4px] py-3">dipendenti</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {projectsState.findAllCompaniesFromTargetResponse?.data.map(
              (company) => (
                <tr
                  className={clsx(
                    "h-[56px] border-b-[1px] border-b-neutral-100",
                    {
                      "bg-brandPrimary-100": selectedIds.includes(company.id),
                      "bg-white":
                        !selectedIds.includes(company.id) &&
                        !isAlreadyIn(company.id),
                      "bg-neutral-200 opacity-70":
                        !selectedIds.includes(company.id) &&
                        isAlreadyIn(company.id),
                    }
                  )}
                  key={company.id}
                >
                  <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                    <Checkbox
                      shape={"square"}
                      disabled={isAlreadyIn(company.id)}
                      checked={
                        selectedIds.includes(company.id) ||
                        isAlreadyIn(company.id)
                      }
                      onCheck={function (value: boolean): void {
                        if (selectedIds.includes(company.id)) {
                          setSelectedIds([
                            ...selectedIds.filter((id) => id !== company.id),
                          ]);
                        } else {
                          setSelectedIds([...selectedIds, company.id]);
                        }
                      }}
                    />
                  </td>
                  <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-brandPrimary-600">
                    {company.name ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                    {company.email ? (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={"mailto:" + company.email}
                        className="text-text-md font-semibold text-neutral-600"
                      >
                        {company.email}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                    {company.phone ? (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={"tel:" + company.phone}
                        className="text-text-md font-semibold text-neutral-600"
                      >
                        {company.phone}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.address ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.zipCode ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.city ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.province ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.countryRegion ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.state ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.revenue ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.vat ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.atecoCode ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.sector ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.category ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={company.website ?? undefined}
                      className="w-full flex"
                    >
                      <GlobeIcon
                        color={
                          company.website
                            ? colors.neutral[400]
                            : colors.neutral[200]
                        }
                        size={20}
                      />
                    </a>
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {company.employeesNumber ?? "-"}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        found={projectsState.findAllCompaniesFromTargetResponse?.total || 0}
        key={"pagination-companies-bottom"}
        pages={projectsState.findAllCompaniesFromTargetResponse?.totalPage || 0}
        currentPage={
          projectsState.findAllCompaniesFromTargetResponse?.page || 0
        }
        setPage={(page) => {
          setPage(page);
        }}
      />
    </div>
  );
}
