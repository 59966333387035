import { ReactElement } from "react";
import { useAppDispatch } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { CheckCircleIcon } from "../../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../../ui/icons/circle";
import { LinkedinIcon } from "../../../../../ui/icons/linkedin";
import { PersonDTO } from "../../../../database/people/dto";
import { setSelectedPerson } from "../../../../database/people/slice";

interface Props {
  person: PersonDTO;
  column: string;
}
export function PeopleColumnMap(props: Props) {
  const dispatch = useAppDispatch();

  const ColumnMap = new Map<string, ReactElement>([
    [
      "Contatto",
      <td
        onClick={() => dispatch(setSelectedPerson(props.person.id))}
        className="px-[4px] cursor-pointer min-w-[150px] text-text-md font-semibold text-brandPrimary-600"
      >
        {(props.person.name ?? "-") + " " + (props.person.surname ?? "-")}
      </td>,
    ],
    [
      "Telefono",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.person.phone ? (
          <a
            rel="noreferrer"
            target="_blank"
            href={"tel:" + props.person.phone}
            className="text-text-md font-semibold text-neutral-600"
          >
            {props.person.phone}
          </a>
        ) : (
          "-"
        )}
      </td>,
    ],
    [
      "Email",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        {props.person.email ? (
          <a
            rel="noreferrer"
            target="_blank"
            href={"mailto:" + props.person.email}
            className="text-text-md font-semibold text-neutral-600"
          >
            {props.person.email}
          </a>
        ) : (
          "-"
        )}
      </td>,
    ],
    [
      "Profilo Linkedin",
      <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
        <a
          rel="noreferrer"
          target="_blank"
          href={props.person.linkedinProfile ?? undefined}
          className="w-full flex"
        >
          <LinkedinIcon
            color={
              props.person.linkedinProfile
                ? colors.neutral[400]
                : colors.neutral[200]
            }
            size={20}
          />
        </a>
      </td>,
    ],
    [
      "Ruolo",
      <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
        {props.person.role ?? "-"}
      </td>,
    ],
    [
      "Azienda",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-brandPrimary-600">
        {props.person.companyName ?? "-"}
      </td>,
    ],
    [
      "Reparto",
      <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
        {props.person.department ?? "-"}
      </td>,
    ],
    [
      "Privacy",
      <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
        <div className="w-full flex justify-center">
          {props.person.privacy ? (
            <CheckCircleIcon color={colors.neutral[400]} size={20} />
          ) : (
            <CircleIcon color={colors.neutral[400]} size={20} />
          )}
        </div>
      </td>,
    ],
  ]);

  return <>{ColumnMap.get(props.column)}</>;
}
