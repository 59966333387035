import clsx from "clsx";
import { useState } from "react";
import { colors } from "../../../../../ui/colors";
import { ArrowRightIcon } from "../../../../../ui/icons/arrowRight";
import { DatabaseIcon } from "../../../../../ui/icons/database";
import Button from "../../../../../ui/molecules/button";
import Checkbox from "../../../../../ui/molecules/checkbox";
import { TargetListPageEnum } from "../../../../targetList/dto";
import { useAppDispatch } from "../../../../../lib/redux/hooks";
import { setTargetListPage } from "../../../../targetList/slice";
import { FileTextIcon } from "../../../../../ui/icons/fileText";
import { UserCheckIcon } from "../../../../../ui/icons/userCheck";

export function ImportType() {
    const [value, setValue] = useState<undefined | TargetListPageEnum.DB | TargetListPageEnum.CSV | TargetListPageEnum.TL>(undefined)
    const dispatch = useAppDispatch()

    return (
        <div className="flex flex-col h-full overflow-auto">
            <div className="p-4 bg-brandPrimary-50 flex justify-between items-center border-y border-y-neutral-100">
                <span className="text-text-md font-bold text-neutral-800">Seleziona tipo di importazione</span>
                <Button
                    size={"sm"}
                    iconPosition={"right"}
                    variant={"ghost"}
                    color={"blue"}
                    label="Successivo"
                    icon={<ArrowRightIcon color={""} size={0} />}
                    disabled={value === undefined}
                    onClick={() => {
                        dispatch(setTargetListPage(value))
                    }}
                />
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-center p-6 gap-6 overflow-auto h-full">
                <div onClick={() => setValue(TargetListPageEnum.DB)} className={clsx("flex cursor-pointer relative items-center justify-start flex-grow flex-col gap-4 p-10 rounded-lg border border-brandPrimary-400 bg-white", { "bg-brandPrimary-50": value === TargetListPageEnum.DB })}>
                    <DatabaseIcon color={colors.brandPrimary[500]} size={64} />
                    <div className="flex flex-col items-center gap-1 text-center">
                        <span className="text-heading-xs font-bold text-neutral-800">Aggiungi dal Database</span>
                        <span className="text-text-md text-neutral-800">Aggiungi dal database generale i contatti in linea con il target e clicca sul pulsante “Importa Contatti”</span>
                    </div>
                    <div className="absolute top-6 right-6">
                        <Checkbox shape={"circle"} size="medium" checked={value === TargetListPageEnum.DB} onCheck={function (value: boolean): void {
                            setValue(TargetListPageEnum.DB)
                        }} />
                    </div>
                </div>
                <div onClick={() => setValue(TargetListPageEnum.CSV)} className={clsx("flex cursor-pointer relative items-center flex-grow flex-col gap-4 p-10 rounded-lg border border-brandPrimary-400 bg-white", { "bg-brandPrimary-50": value === TargetListPageEnum.CSV })}>
                    <FileTextIcon color={colors.brandPrimary[500]} size={64} />
                    <div className="flex flex-col items-center gap-1 text-center">
                        <span className="text-heading-xs font-bold text-neutral-800">Aggiungi da file CVS</span>
                        <span className="text-text-md text-neutral-800">Aggiungi da file CSV i contatti in linea con il target e clicca sul pulsante “Importa Contatti”</span>
                    </div>
                    <div className="absolute top-6 right-6">
                        <Checkbox shape={"circle"} size="medium" checked={value === TargetListPageEnum.CSV} onCheck={function (value: boolean): void {
                            setValue(TargetListPageEnum.CSV)
                        }} />
                    </div>
                </div>
                <div onClick={() => setValue(TargetListPageEnum.TL)} className={clsx("flex cursor-pointer relative items-center flex-grow flex-col gap-4 p-10 rounded-lg border border-brandPrimary-400 bg-white", { "bg-brandPrimary-50": value === TargetListPageEnum.TL })}>
                    <UserCheckIcon color={colors.brandPrimary[500]} size={64} />
                    <div className="flex flex-col items-center gap-1 text-center">
                        <span className="text-heading-xs font-bold text-neutral-800">Aggiungi da liste target</span>
                        <span className="text-text-md text-neutral-800">Seleziona da altre liste presenti i contatti in linea con il target e clicca sul pulsante “Importa Contatti”</span>
                    </div>
                    <div className="absolute top-6 right-6">
                        <Checkbox shape={"circle"} size="medium" checked={value === TargetListPageEnum.TL} onCheck={function (value: boolean): void {
                            setValue(TargetListPageEnum.TL)
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
}