import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { encodeBase64, groupBy } from "../../lib/utils";
import { Layout } from "../../ui/layout";
import Avatar from "../../ui/molecules/avatar";
import Button from "../../ui/molecules/button";
import Input from "../../ui/molecules/input";
import { SelectCustom } from "../../ui/molecules/select";
import { Spinner } from "../../ui/molecules/spinner";
import { Banner } from "../../ui/organisms/banner";
import FileUploader from "../../ui/organisms/fileUploader";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { UserRole, UserRoleMap } from "./dto";
import { editUser, findClients, findCollaborators, findUserAvatar, findUserById, setFindUserByIdStatus, setUpdateUserCollaborators, setUpdateUserCompanyAtecoCode, setUpdateUserCompanyName, setUpdateUserCompanyRevenue, setUpdateUserCompanySector, setUpdateUserCompanyVat, setUpdateUserCustomerId, setUpdateUserName, setUpdateUserPhone, setUpdateUserStatus, setUpdateUserSurname, setValidateUpdateUserCompanyVat, setValidateUpdateUserName, setValidateUpdateUserPhone, setValidateUpdateUserStatus, setValidateUpdateUserSurname, validateUpdateUser } from "./slice";
import { NumericFormat } from "react-number-format";

export function EditUser() {
    const usersState = useAppSelector(state => state.users)
    const preferencesState = useAppSelector(state => state.preferences)
    const dispatch = useAppDispatch()

    const formData = new FormData()

    const [file, setFile] = useState<File | null>(null)
    const [file64, setFile64] = useState<string>('')

    useEffect(() => {
        dispatch(findCollaborators())
        dispatch(findClients())
        dispatch(findUserById(usersState.selectedUser!))
        dispatch(setValidateUpdateUserName(false))
        dispatch(setValidateUpdateUserPhone(false))
        dispatch(setValidateUpdateUserSurname(false))
        dispatch(setValidateUpdateUserCompanyVat(false))
    }, [])

    useEffect(() => {
        if (usersState.findUserByIdStatus === 'successfully') {
            dispatch(setFindUserByIdStatus('idle'))
            dispatch(setUpdateUserCollaborators(usersState.findUserByIdResponse?.collaborators))
            dispatch(setUpdateUserCustomerId(usersState.findUserByIdResponse?.customerId))
            dispatch(setUpdateUserPhone(usersState.findUserByIdResponse?.phone))
            dispatch(setUpdateUserName(usersState.findUserByIdResponse?.name))
            dispatch(setUpdateUserSurname(usersState.findUserByIdResponse?.surname))
            dispatch(setUpdateUserCompanyAtecoCode(usersState.findUserByIdResponse?.companyAtecoCode))
            dispatch(setUpdateUserCompanyName(usersState.findUserByIdResponse?.companyName))
            dispatch(setUpdateUserCompanyRevenue(usersState.findUserByIdResponse?.companyRevenue))
            dispatch(setUpdateUserCompanySector(usersState.findUserByIdResponse?.companySector))
            dispatch(setUpdateUserCompanyVat(usersState.findUserByIdResponse?.companyVat))
            if (usersState.findUserByIdResponse?.avatarObjectId !== undefined && usersState.findUserByIdResponse?.avatarObjectId !== null)
                dispatch(findUserAvatar(usersState.findUserByIdResponse?.avatarObjectId))
            else {
                setFile(null)
                setFile64('')
            }
        }
    }, [usersState.findUserByIdStatus])

    useEffect(() => {
        if (usersState.validateUpdateUserStatus === 'successfully') {
            formData.delete('file')
            formData.delete('user')
            if (file)
                formData.append('file', file)
            formData.append('user', JSON.stringify(usersState.editUserRequest))
            dispatch(editUser({ id: usersState.findUserByIdResponse?.id!, data: formData }))
            dispatch(setValidateUpdateUserStatus('idle'))
        }
    }, [usersState.validateUpdateUserStatus])

    useEffect(() => {
        if (usersState.findUserAvatarStatus === 'successfully') {
            setFile64(usersState.findUserAvatarResponse!)
        }
    }, [usersState.findUserAvatarStatus])

    useEffect(() => {
        if (usersState.editUserRequest.companyAtecoCode === undefined) {
            dispatch(setUpdateUserCompanySector(undefined))
        } else {
            const sector = preferencesState.findAllAtecosResponse.find(_ateco => _ateco.atecoCode === usersState.editUserRequest.companyAtecoCode)?.sector
            if (sector && usersState.editUserRequest.companySector !== sector) {
                dispatch(setUpdateUserCompanySector(sector))
            }
        }
    }, [usersState.editUserRequest.companyAtecoCode])

    const filteredAtecos = preferencesState.findAllAtecosResponse
        .filter(ateco => usersState.editUserRequest.companyAtecoCode !== undefined ? usersState.editUserRequest.companyAtecoCode === ateco.atecoCode : ateco)


    return (
        <Layout
            key={'edit-user'}
            menuItem={MenuItems.USERS}
            breadcrumbItems={['Utenti', 'Modifica utente']}
            headerBackPath={'/users'}
            headerLabel={usersState.findUserByIdResponse?.name + ' ' + usersState.findUserByIdResponse?.surname}
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end">
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        label="Aggiorna"
                        variant={"solid"}
                        color={"blue"}
                        onClick={() => {
                            dispatch(validateUpdateUser(usersState.editUserRequest))
                        }}
                    />
                </div>
            }
        >
            <div className={"gap-[24px] flex flex-col w-[100%] h-full p-[24px]"}>
                <Banner
                    type="success"
                    visible={usersState.editUserStatus === 'successfully'}
                    label={"Utente aggiornato correttamente"}
                    closeAction={function (): void {
                        dispatch(setUpdateUserStatus('idle'))
                    }}
                />
                <Banner
                    type="error"
                    visible={usersState.editUserStatus === 'failed'}
                    label={"Si è verificato un errore durante la modifica dell'utente"}
                    closeAction={function (): void {
                        dispatch(setUpdateUserStatus('idle'))
                    }}
                />
                {
                    usersState.findUserByIdStatus === 'loading' ?
                        <div className="h-[284px] w-full rounded-xl shadow-sm bg-white flex items-center justify-center">
                            <Spinner color="primary" size={40} />
                        </div> :
                        <div className="shadow-sm p-[24px] gap-[20px] flex flex-col bg-white rounded-[12px]">
                            <div className="flex gap-[24px] w-[100%] items-start">
                                <div className="w-[240px] text-label-md font-medium text-neutral-600">Ruolo</div>
                                <div className="flex gap-[16px] w-[100%]">
                                    <SelectCustom
                                        disabled
                                        isClearable
                                        key={'edit-user-roles-list'}
                                        error={usersState.createUserErrors.role}
                                        errorLabel="Inserire un ruolo."
                                        defaultValue={usersState.findUserByIdResponse?.role}
                                        placeholder={"Seleziona un ruolo"}
                                        options={[
                                            { label: UserRoleMap.get(UserRole.collaborator)!, value: UserRole.collaborator },
                                            { label: UserRoleMap.get(UserRole.commercial)!, value: UserRole.commercial },
                                            { label: UserRoleMap.get(UserRole.customer)!, value: UserRole.customer },
                                            { label: UserRoleMap.get(UserRole.team_leader)!, value: UserRole.team_leader },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-[24px] w-[100%] items-start">
                                <div className="w-[240px] text-text-md font-semibold text-neutral-600">Email</div>
                                <div className="flex gap-[16px] w-[100%]">
                                    <Input disabled value={usersState.findUserByIdResponse?.email} />
                                </div>
                            </div>
                            <div className="flex gap-[24px] w-[100%] items-start">
                                <div className="w-[240px] text-text-md font-semibold text-neutral-600">Nome</div>
                                <div className="flex gap-[16px] w-[100%]">
                                    <Input error={usersState.updateUserErrors.name} supportingText={usersState.updateUserErrors.name ? 'Inserire il nome.' : ''} placeholder="Nome" value={usersState.editUserRequest?.name} onChangeText={e => { dispatch(setUpdateUserName(e)); if (usersState.updateUserErrors.name) dispatch(setValidateUpdateUserName(false)) }} />
                                    <Input error={usersState.updateUserErrors.surname} supportingText={usersState.updateUserErrors.surname ? 'Inserire il cognome.' : ''} placeholder="Cognome" value={usersState.editUserRequest?.surname} onChangeText={e => { dispatch(setUpdateUserSurname(e)); if (usersState.updateUserErrors.surname) dispatch(setValidateUpdateUserSurname(false)) }} />
                                </div>
                            </div>
                            <div className="flex gap-[24px] w-[100%] items-start">
                                <div className="w-[240px] text-text-md font-semibold text-neutral-600">Telefono</div>
                                <div className="flex gap-[16px] w-[100%]">
                                    <Input error={usersState.updateUserErrors.phone} supportingText={usersState.updateUserErrors.phone ? 'Inserire un numero di telefono valido.' : ''} placeholder="Telefono" value={usersState.editUserRequest?.phone} onChangeText={e => { dispatch(setUpdateUserPhone(e)); if (usersState.updateUserErrors.phone) dispatch(setValidateUpdateUserPhone(false)) }} />
                                </div>
                            </div>
                            {
                                usersState.findUserByIdResponse?.role === UserRole.customer &&
                                <>
                                    <div className="flex gap-[24px] w-[100%] items-start">
                                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Collaboratori</div>
                                        <div className="flex gap-[16px] w-[100%]">
                                            <SelectCustom
                                                isClearable
                                                key={'edit-user-collaborators-list'}
                                                placeholder={"Seleziona i collaboratori"}
                                                onChange={(value) => dispatch(setUpdateUserCollaborators(value))}
                                                isMulti
                                                defaultValue={usersState.editUserRequest.collaborators!}
                                                options={
                                                    usersState.findCollaboratorsResponse?.data
                                                        .filter(collaborator => collaborator.customerId === null || collaborator.customerId === usersState.findUserByIdResponse?.id)
                                                        .map((value) => ({ label: (value.name + ' ' + value.surname), value: value.id }))!
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-[24px] w-[100%] items-start">
                                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Nome azienda</div>
                                        <div className="flex gap-[16px] w-[100%]">
                                            <Input placeholder="Nome azienda" onChangeText={value => { dispatch(setUpdateUserCompanyName(value)) }} value={usersState.editUserRequest?.companyName} />
                                        </div>
                                    </div>
                                    <div className="flex gap-[24px] w-[100%] items-start">
                                        <div className="w-[240px] text-label-md font-medium text-neutral-600">P. IVA azienda</div>
                                        <div className="flex gap-[16px] w-[100%]">
                                            <Input supportingText={usersState.updateUserErrors.companyVat ? "Inserire una partita IVA valida." : undefined} error={usersState.updateUserErrors.companyVat} placeholder="P. IVA" onChangeText={value => { dispatch(setUpdateUserCompanyVat(value)); if (usersState.createUserErrors.companyVat) dispatch(setValidateUpdateUserCompanyVat(false)) }} value={usersState.editUserRequest?.companyVat} />
                                        </div>
                                    </div>
                                    <div className="flex gap-[24px] w-[100%] items-start">
                                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Codice ATECO</div>
                                        <div className="flex gap-[16px] w-[100%]">
                                            <SelectCustom
                                                placeholder={"Cerca per ATECO"}
                                                onChange={(value) => dispatch(setUpdateUserCompanyAtecoCode(value))}
                                                isClearable
                                                key={'update-user-ateco-code'}
                                                defaultValue={usersState.editUserRequest.companyAtecoCode}
                                                noOptionsMessage="Non sono presenti ateco."
                                                options={preferencesState.findAllAtecosResponse.map(ateco => ({ value: ateco.atecoCode, label: ateco.atecoCode + ' - ' + ateco.atecoDescription }))} />
                                        </div>
                                    </div>
                                    <div className="flex gap-[24px] w-[100%] items-start">
                                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Settore</div>
                                        <div className="flex gap-[16px] w-[100%]">
                                            <SelectCustom
                                                placeholder={"Seleziona il settore"}
                                                disabled
                                                key={'new-user-sector'}
                                                defaultValue={usersState.editUserRequest.companySector}
                                                options={groupBy(filteredAtecos, 'sector').map(sector => ({ value: sector.key, label: sector.key }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-[24px] w-[100%] items-start">
                                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Fatturatato azienda</div>
                                        <div className="flex gap-[16px] w-[100%]">
                                            <NumericFormat
                                                prefix="€ "
                                                value={usersState.editUserRequest.companyRevenue}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale
                                                customInput={Input}
                                                id={"update-user-request-company-revenue"}
                                                key={"update-user-request-company-revenue"}
                                                placeholder={"Fatturato"}
                                                onValueChange={(e) => {
                                                    dispatch(setUpdateUserCompanyRevenue(e.floatValue));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                usersState.findUserByIdResponse?.role === UserRole.collaborator &&
                                <div className="flex gap-[24px] w-[100%] items-start">
                                    <div className="w-[240px] text-label-md font-medium text-neutral-600">Cliente</div>
                                    <div className="flex gap-[16px] w-[100%]">
                                        <SelectCustom
                                            isClearable
                                            placeholder={"Seleziona il cliente"}
                                            key={'edit-user-customer-list'}
                                            defaultValue={usersState.editUserRequest.customerId!}
                                            onChange={(value) => dispatch(setUpdateUserCustomerId(value))}
                                            options={usersState.findClientsResponse?.data.map((value) => ({ label: (value.name + ' ' + value.surname), value: value.id }))!}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="flex gap-[24px] w-[100%] items-start">
                                <div className="w-[240px] text-label-md font-medium text-neutral-600">Immagine del profilo</div>
                                <div className="flex gap-[16px] w-[100%]">
                                    {
                                        usersState.findUserAvatarStatus === 'loading' ?
                                            <Spinner /> :
                                            <Avatar type="single" size={"2xl"} shape={'circle'} imageUrl={file64} altTextInitials={(usersState.editUserRequest.name[0] || '') + (usersState.editUserRequest.surname[0] || '')} />
                                    }
                                    <FileUploader id={"new-user-file-uploader"} style={"small"} onChange={e => {
                                        setFile(e[0])
                                        e[0].arrayBuffer().then(_e => (setFile64(encodeBase64(_e))))
                                    }} />
                                </div>
                            </div>
                        </div>
                }
            </div>
        </Layout>
    )
}