import React, { useState } from "react";
import "./Button.css";
import { ButtonProps, buttonIconPositionMap, buttonLabelSizeMap, buttonSizeMap, buttonVariantKey, colorKey } from "./Button.types";

const Button: React.FC<ButtonProps> = ({
    ...props
}) => {
    const [isDarker, setIsDarker] = useState(false);

    const handleMouseEnter = () => setIsDarker(true);
    const handleMouseLeave = () => setIsDarker(false);
    const handleFocus = () => setIsDarker(true);
    const handleBlur = () => setIsDarker(false);

    if ("paintWorklet" in CSS) {
        //@ts-ignore
        CSS.paintWorklet.addModule(
            "https://www.unpkg.com/css-houdini-squircle@0.3.0/squircle.min.js"
        );
    }

    return (
        <button
            className="transition-all"
            onClick={() => props.onClick && props.onClick()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={props.disabled}
            style={{
                ...buttonSizeMap.get(props.size),
                ...colorKey(props.color, buttonVariantKey(props.variant, isDarker), true).buttonVariant,
                ...buttonIconPositionMap.get(props.iconPosition),
                ...{ borderRadius: '8px', width: props.fullWidth ? '100%' : '', display: "flex", justifyContent: 'center', position: "relative", alignItems: 'center', gap: 8, borderWidth: '1px', borderStyle: 'solid', cursor: 'pointer' },
            }}
        >
            {props.iconPosition !== 'off' && props.icon && React.cloneElement(props.icon, { color: colorKey(props.color, buttonVariantKey(props.variant, isDarker), false).buttonIconVariant, size: 24 })}
            {
                props.label && props.iconPosition !== 'only' &&
                <span style={{ ...buttonLabelSizeMap.get(props.size), ...{ color: colorKey(props.color, buttonVariantKey(props.variant, isDarker), false).buttonIconVariant } }}>{props.label}</span>
            }
        </button>
    )
};

export default Button;