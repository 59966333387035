import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { EditIcon } from "../../../../ui/icons/edit";
import { SortIcon } from "../../../../ui/icons/sort";
import { TrashIcon } from "../../../../ui/icons/trash";
import Avatar from "../../../../ui/molecules/avatar";
import Pills from "../../../../ui/molecules/pills";
import { ConfirmModal } from "../../../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../../../ui/organisms/dropdown";
import { Pagination } from "../../../../ui/organisms/pagination";
import { targertListsStatePillMap } from "../../../targetList/dto";
import {
  deleteTargetList,
  findAllTArgetLists,
  findTargetListById,
  setDeleteTargetListStatus,
  setSelectedTargetList,
} from "../../../targetList/slice";
import { findProjectById } from "../../slice";

export function TargetListTable() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListsState = useAppSelector((state) => state.targetList);
  const usersState = useAppSelector((state) => state.users);

  const [idToDelete, setIdToDelete] = useState<string | undefined>(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListsState.deleteTargetListStatus === "failed" ||
      targetListsState.deleteTargetListStatus === "successfully"
    ) {
      if (targetListsState.deleteTargetListStatus === "successfully") {
        dispatch(findProjectById(projectsState.selectedProject!));
        dispatch(findTargetListById(targetListsState.selectedTargetList!));
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteTargetListStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListsState.deleteTargetListStatus]);

  return (
    <div className="w-[100%] flex flex-col">
      <div>
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Nome target
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('name')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Commerciali
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('customer.companySector')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Settori
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('teamLeader.name')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Numero persone
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('commercials.name')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Numero aziende
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('startDate')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  lead
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('endDate')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  da iniziare
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('state')); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Stato
                  <div
                    // onClick={() => { dispatch(setProjectFilterSort('targetListSize'projectsState)); dispatch(setProjectFilterOrder(!projectsState.filters.order)) }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left pr-4 pl-[4px]">
                <div className="flex gap-[4px] px-[4px] w-[20px]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {projectsState.findProjectResponse?.targetLists?.map((target) => (
              <tr
                className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                key={target.id}
              >
                <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                  {target.name}
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Avatar
                    type="group"
                    size="sm"
                    length={2}
                    imageUrls={target.commercials.map((commercial) => ({
                      altTextInitials:
                        commercial.name[0] + commercial.surname[0],
                      url:
                        usersState.findUsersAvatarsListResponse.find(
                          (avatar) => avatar.userId === commercial.id
                        )?.objectId ?? "ciaoooo",
                    }))}
                  />
                </td>
                <td className="px-[4px] text-text-md font-semibold text-neutral-600">
                  {/* {target.sectors.length > 0 ? <Pills size={"xs"} label={target.sectors[0] + (target.sectors.length > 1 ? (' + ' + (target.sectors.length - 1)) : '')} outline={false} emphasis={false} color={"blue"} /> : ""} */}
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={target.personContacts?.length.toString() ?? "0"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={target.companyContacts?.length.toString() ?? "0"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={"Stato lead"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={"Stato da lavorare"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={targertListsStatePillMap.get(target.state)?.label!}
                    outline={false}
                    emphasis={false}
                    color={targertListsStatePillMap.get(target.state)?.color!}
                  />
                </td>
                <td className="w-[20px] cursor-pointer">
                  <Dropdown
                    items={[
                      {
                        label: "Modifica",
                        icon: <EditIcon color={""} size={0} />,
                        onClick: () =>
                          dispatch(setSelectedTargetList(target.id)),
                      },
                      {
                        label: "Cancella",
                        icon: <TrashIcon color={""} size={0} />,
                        onClick: () => setIdToDelete(target.id),
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          found={0}
          pages={1}
          currentPage={0}
          setPage={(page) => {}}
        />
      </div>
      <ConfirmModal
        title={"Elimina lista target"}
        label={"Sei sicuro di voler eliminare questa lista target?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteTargetList(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
