import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { EditTargetList } from "../projects/editProject/editTargetList";
import { findTargetListById, setRemoveContactsStatus } from "./slice";

export function EditTargetListPage() {
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(findTargetListById(targetListsState.selectedTargetList!));
  }, []);

  return (
    <Layout
      headerLabel={"Modifica lista"}
      headerBackPath="/target-list"
      breadcrumbItems={[
        "Liste target",
        targetListsState.findTargetListResponse?.name!,
      ]}
      menuItem={MenuItems.TARGET_LIST}
    >
      <div className="fixed flex flex-col gap-4 top-6 right-6">
        <Banner
          label={"Contatto rimosso dalla lista."}
          visible={targetListsState.removeContactsStatus === "successfully"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"success"}
        />
        <Banner
          label={
            "Si è verificato un errore durante la rimozione del contatto dalla lista target."
          }
          visible={targetListsState.removeContactsStatus === "failed"}
          closeAction={function (): void {
            dispatch(setRemoveContactsStatus("idle"));
          }}
          type={"error"}
        />
      </div>
      <EditTargetList />
    </Layout>
  );
}
