import { ImportCSVResponseDTO } from "../database/dto";
import {
  ContactStateEnum,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  TargetListDTO,
} from "./dto";
import { TargetListsServiceImpl } from "./serviceImpl";

export interface TargetListsService {
  deleteTargetList(id: string): Promise<void>;
  createTargetList(request: NewTargetListRequest): Promise<string>;
  findTargetListById(id: string): Promise<TargetListDTO>;
  findAllTargetLists(
    filters: FindAllTargetListsFilters
  ): Promise<FindAllTargetListsResponse>;
  importContactCSV(
    data: FormData,
    type: "persons" | "companies",
    targetListId: string
  ): Promise<ImportCSVResponseDTO>;
  removeContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void>;
  changeContactStatus(
    targetListId: string,
    type: "persons" | "companies",
    contactState: ContactStateEnum,
    contactId: string
  ): Promise<void>;
  addContacts(
    targetListId: string,
    type: "persons" | "companies",
    contactIds: string[]
  ): Promise<void>;
}

export function NewTargetListsService(): TargetListsService {
  return new TargetListsServiceImpl();
}
