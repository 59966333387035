import qs from "qs";
import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import {
  EditProjectRequest,
  FindAllProjectsFilters,
  FindAllProjectsResponse,
  NewProjectRequest,
  ProjectDTO,
} from "./dto";
import { ProjectsService } from "./service";

export class ProjectsServiceImpl implements ProjectsService {
  public findAllProjects(
    filters: FindAllProjectsFilters
  ): Promise<FindAllProjectsResponse> {
    return cdAxios
      .get("/api/projects", {
        params: {
          itemsPerPage:
            filters.itemsPerPage === 0 ? undefined : filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          name: filters.name,
          sector: filters.sector,
          teamLeaderId: filters.teamLeaderId,
          commercial: filters.commercial,
          state: filters.state,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: false,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findProjectById(id: string): Promise<ProjectDTO> {
    let idToUse: string = "";
    if (window.location.pathname.includes("edit-project")) {
      if (window.location.pathname.split("/")[2] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[2];
      }
    } else {
      idToUse = id;
    }
    return cdAxios
      .get("/api/projects/" + idToUse, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public editProject(id: string, data: EditProjectRequest): Promise<void> {
    return cdAxios
      .put("/api/projects/" + id, data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public createProject(request: NewProjectRequest): Promise<string> {
    return cdAxios
      .post("/api/projects", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public deleteProject(id: string): Promise<void> {
    return cdAxios
      .delete("/api/projects/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }
}
