import { EditProjectRequest, FindAllProjectsFilters, FindAllProjectsResponse, NewProjectRequest, ProjectDTO } from "./dto";
import { ProjectsServiceImpl } from "./serviceImpl";

export interface ProjectsService {
    findAllProjects(filters: FindAllProjectsFilters): Promise<FindAllProjectsResponse>
    createProject(request: NewProjectRequest): Promise<string>
    deleteProject(id: string): Promise<void>
    editProject(id: string, data: EditProjectRequest): Promise<void>
    findProjectById(id: string): Promise<ProjectDTO>
}

export function NewProjectsService(): ProjectsService {
    return new ProjectsServiceImpl();
}
