import clsx from "clsx";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../lib/redux/hooks";
import { colors } from "../../../../../../ui/colors";
import { GlobeIcon } from "../../../../../../ui/icons/globe";
import { LinkedinIcon } from "../../../../../../ui/icons/linkedin";
import Checkbox from "../../../../../../ui/molecules/checkbox";
import { Pagination } from "../../../../../../ui/organisms/pagination";
import { setFindAllCompaniesFiltersPage } from "../../../../../database/companies/slice";
import { CheckCircleIcon } from "../../../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../../../ui/icons/circle";

interface Props {
  selectedIds: string[];
  setSelectedIds: (values: string[]) => void;
  setPage: (page: number) => void;
}
export function ImportFromDatabaseList(props: Props) {
  const { selectedIds, setSelectedIds, setPage } = props;
  const targetListState = useAppSelector((state) => state.targetList);
  const projectsState = useAppSelector((state) => state.projects);

  const dispatch = useAppDispatch();

  const isAlreadyIn = (id: string): boolean => {
    return targetListState.findTargetListResponse?.personContacts &&
      targetListState.findTargetListResponse?.personContacts[id] !== undefined
      ? true
      : false;
  };

  return (
    <div className="w-full">
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4 w-10">
                <Checkbox
                  shape={"square"}
                  disabled={
                    projectsState.findAllPeopleFromTargetResponse?.data.every(
                      (company) => isAlreadyIn(company.id)
                    )!
                  }
                  checked={
                    projectsState.findAllPeopleFromTargetResponse?.data
                      .filter((company) => !isAlreadyIn(company.id))
                      .every((company) => selectedIds.includes(company.id))! ||
                    projectsState.findAllPeopleFromTargetResponse?.data.every(
                      (company) => isAlreadyIn(company.id)
                    )!
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      projectsState.findAllPeopleFromTargetResponse?.data
                        .filter((company) => !isAlreadyIn(company.id))
                        .every((company) => selectedIds.includes(company.id))
                    ) {
                      let newSelected = [...selectedIds];
                      projectsState.findAllPeopleFromTargetResponse?.data.forEach(
                        (company) => {
                          newSelected = newSelected.filter(
                            (id) => company.id !== id
                          );
                        }
                      );
                      setSelectedIds([...newSelected]);
                    } else {
                      setSelectedIds([
                        ...selectedIds,
                        ...projectsState.findAllPeopleFromTargetResponse?.data
                          .filter(
                            (company) =>
                              !selectedIds.includes(company.id) &&
                              !isAlreadyIn(company.id)
                          )
                          .map((company) => company.id)!,
                      ]);
                    }
                  }}
                />
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">contatto</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">telefono</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">email</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  <LinkedinIcon color={colors.neutral[400]} size={16} />
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">ruolo</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">reparto</div>
              </th>
              <th className="text-left  pr-4">
                <div className="flex gap-[4px] px-[4px] py-3">privacy</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {projectsState.findAllPeopleFromTargetResponse?.data.map(
              (person) => (
                <tr
                  className={clsx(
                    "h-[56px] border-b-[1px] border-b-neutral-100",
                    {
                      "bg-brandPrimary-100": selectedIds.includes(person.id),
                      "bg-white":
                        !selectedIds.includes(person.id) &&
                        !isAlreadyIn(person.id),
                      "bg-neutral-200 opacity-70":
                        !selectedIds.includes(person.id) &&
                        isAlreadyIn(person.id),
                    }
                  )}
                  key={person.id}
                >
                  <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                    <Checkbox
                      shape={"square"}
                      disabled={isAlreadyIn(person.id)}
                      checked={
                        selectedIds.includes(person.id) ||
                        isAlreadyIn(person.id)
                      }
                      onCheck={function (value: boolean): void {
                        if (selectedIds.includes(person.id)) {
                          setSelectedIds([
                            ...selectedIds.filter((id) => id !== person.id),
                          ]);
                        } else {
                          setSelectedIds([...selectedIds, person.id]);
                        }
                      }}
                    />
                  </td>
                  <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-brandPrimary-600">
                    {(person.name ?? "-") + " " + (person.surname ?? "-")}
                  </td>
                  <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                    {person.phone ? (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={"tel:" + person.phone}
                        className="text-text-md font-semibold text-neutral-600"
                      >
                        {person.phone}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                    {person.email ? (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={"mailto:" + person.email}
                        className="text-text-md font-semibold text-neutral-600"
                      >
                        {person.email}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={person.linkedinProfile ?? undefined}
                      className="w-full flex"
                    >
                      <LinkedinIcon
                        color={
                          person.linkedinProfile
                            ? colors.neutral[400]
                            : colors.neutral[200]
                        }
                        size={20}
                      />
                    </a>
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {person.role ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-brandPrimary-600">
                    {person.companyName ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    {person.department ?? "-"}
                  </td>
                  <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                    <div className="w-full flex justify-center">
                      {person.privacy ? (
                        <CheckCircleIcon
                          color={colors.neutral[400]}
                          size={20}
                        />
                      ) : (
                        <CircleIcon color={colors.neutral[400]} size={20} />
                      )}
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        found={projectsState.findAllPeopleFromTargetResponse?.total || 0}
        key={"pagination-companies-bottom"}
        pages={projectsState.findAllPeopleFromTargetResponse?.totalPage || 0}
        currentPage={projectsState.findAllPeopleFromTargetResponse?.page || 0}
        setPage={(page) => {
          setPage(page);
        }}
      />
    </div>
  );
}
