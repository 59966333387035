import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { roleDepartment } from "../../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { groupBy } from "../../../../lib/utils";
import Input from "../../../../ui/molecules/input";
import { SelectCustom } from "../../../../ui/molecules/select";
import { setNewTargetListCategories, setNewTargetListCommercialIds, setNewTargetListDepartments, setNewTargetListEmployeesNumber, setNewTargetListName, setNewTargetListRevenue, setNewTargetListRoles, setNewTargetListSectors } from "../../../targetList/slice";

export function Form() {
    const projectsState = useAppSelector(state => state.projects)
    const targetListState = useAppSelector(state => state.targetList)
    const preferencesState = useAppSelector(state => state.preferences)
    const dispatch = useAppDispatch()

    const [signRevenue, setSignRevenue] = useState<'=' | '<' | '>' | '><' | undefined>(undefined);
    const [firstInputRevenue, setFirstInputRevenue] = useState<number | undefined>(undefined);
    const [secondInputRevenue, setSecondInputRevenue] = useState<number | undefined>(undefined);

    const [signEmployeesNumber, setSignEmployeesNumber] = useState<'=' | '<' | '>' | '><' | undefined>(undefined);
    const [firstInputEmployeesNumber, setFirstInputEmployeesNumber] = useState<number | undefined>(undefined);
    const [secondInputEmployeesNumber, setSecondInputEmployeesNumber] = useState<number | undefined>(undefined);

    const signMap = new Map<('=' | '<' | '>' | '><'), string>([
        ['=', 'Uguale a'],
        ['<', 'Minore di'],
        ['>', 'Maggiore di'],
        ['><', 'Compreso tra'],
    ])

    useEffect(() => {
        if (signRevenue === undefined) {
            setFirstInputRevenue(undefined)
            setSecondInputRevenue(undefined)
        } else if (signRevenue !== '><')
            setSecondInputRevenue(undefined)
        dispatch(setNewTargetListRevenue(signRevenue + ':' + firstInputRevenue + ':' + secondInputRevenue))
    }, [signRevenue, firstInputRevenue, secondInputRevenue])

    useEffect(() => {
        if (signEmployeesNumber === undefined) {
            setFirstInputEmployeesNumber(undefined)
            setSecondInputEmployeesNumber(undefined)
        } else if (signEmployeesNumber !== '><')
            setSecondInputEmployeesNumber(undefined)

        dispatch(setNewTargetListEmployeesNumber(signEmployeesNumber + ':' + firstInputEmployeesNumber + ':' + secondInputEmployeesNumber))
    }, [signEmployeesNumber, firstInputEmployeesNumber, secondInputEmployeesNumber])

    return (
        <div className="flex flex-col gap-6 p-4">
            <div className="flex gap-4">
                <Input
                    label="Nome del Target"
                    placeholder="Nome lista target"
                    error={targetListState.validateNewTargetList.errors.name}
                    supportingText={targetListState.validateNewTargetList.errors.name ? "Inserire il nome della lista target." : ''}
                    defaultValue={targetListState.newTargetListRequest.name}
                    onChangeText={e => dispatch(setNewTargetListName(e))}
                />
                <SelectCustom
                    isMulti
                    error={targetListState.validateNewTargetList.errors.departments}
                    errorLabel="Selezionare almeno un dipartimento."
                    placeholder={"Seleziona i reparti"}
                    options={groupBy(roleDepartment, 'department').map(department => ({ label: department.key, value: department.key }))}
                    label="Seleziona i reparti da contattare"
                    defaultValue={targetListState.newTargetListRequest.departments}
                    onChange={e => dispatch(setNewTargetListDepartments(e))}
                />
                <SelectCustom
                    isMulti
                    error={targetListState.validateNewTargetList.errors.roles}
                    errorLabel="Selezionare almeno un ruolo."
                    placeholder={"Seleziona i ruoli"}
                    disabled={targetListState.newTargetListRequest.departments.length === 0}
                    options={groupBy(roleDepartment.filter(row => targetListState.newTargetListRequest.departments.includes(row.department)), 'role').map(department => ({ label: department.key, value: department.key }))}
                    label="Ruolo"
                    defaultValue={targetListState.newTargetListRequest.roles}
                    onChange={e => dispatch(setNewTargetListRoles(e))}
                />
            </div>
            <div className="flex gap-4">
                <SelectCustom
                    isMulti
                    placeholder={"Seleziona i settori"}
                    error={targetListState.validateNewTargetList.errors.sectors}
                    errorLabel="Selezionare almeno un settore."
                    options={groupBy(preferencesState.findAllAtecosResponse, 'sector').map(sector => ({ label: sector.key, value: sector.key }))}
                    label="Settori"
                    defaultValue={targetListState.newTargetListRequest.sectors}
                    onChange={e => dispatch(setNewTargetListSectors(e))}
                />
                <SelectCustom
                    isMulti
                    placeholder={"Seleziona le categorie"}
                    error={targetListState.validateNewTargetList.errors.categories}
                    disabled={targetListState.newTargetListRequest.sectors.length === 0}
                    errorLabel="Selezionare almeno una categoria."
                    options={groupBy(preferencesState.findAllAtecosResponse.filter(row => targetListState.newTargetListRequest.sectors.includes(row.sector)), 'category').map(category => ({ label: category.key, value: category.key }))}
                    label="Categorie"
                    defaultValue={targetListState.newTargetListRequest.categories}
                    onChange={e => dispatch(setNewTargetListCategories(e))}
                />
                <SelectCustom
                    isMulti
                    placeholder={"Seleziona i commerciali"}
                    error={targetListState.validateNewTargetList.errors.commercials}
                    errorLabel="Selezionare almeno un commerciale."
                    options={projectsState.findProjectResponse?.commercials.map(commercial => ({ label: commercial.name + ' ' + commercial.surname, value: commercial.id }))!}
                    label="Assegna ai commerciali"
                    defaultValue={targetListState.newTargetListRequest.commercialIds}
                    onChange={e => dispatch(setNewTargetListCommercialIds(e))}
                />
            </div>
            <div className="flex gap-4 w-full">
                <div style={{ width: 'calc((100% - 32px) / 3)' }}>
                    <SelectCustom
                        isClearable
                        placeholder={"Inserisci range del fatturato"}
                        options={[
                            { label: 'Uguale a', value: '=' },
                            { label: 'Minore di', value: '<' },
                            { label: 'Maggiore di', value: '>' },
                            { label: 'Compreso tra', value: '><' },
                        ]}
                        label="Fatturato"
                        defaultValue={signRevenue}
                        error={targetListState.validateNewTargetList.errors.revenueSign}
                        errorLabel="Selezionare il tipo di fatturato."
                        onChange={e => setSignRevenue(e as "=" | "<" | ">" | "><" | undefined)}
                    />
                </div>
                {
                    signRevenue !== undefined &&
                    <div style={{ width: 'calc((100% - 32px) / 3)' }}>
                        <NumericFormat
                            prefix="€ "
                            value={firstInputRevenue}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            label={signMap.get(signRevenue)}
                            error={targetListState.validateNewTargetList.errors.revenueFirst}
                            supportingText={targetListState.validateNewTargetList.errors.revenueFirst ? "Inserire il fatturato" : ''}
                            customInput={Input}
                            id={"company-to-confirm-price"}
                            key={"company-to-confirm-price"}
                            placeholder={signMap.get(signRevenue)}
                            onValueChange={(e) => {
                                setFirstInputRevenue(e.floatValue)
                            }}
                        />
                    </div>
                }
                {
                    signRevenue === '><' &&
                    <div style={{ width: 'calc((100% - 32px) / 3)' }}>
                        <NumericFormat
                            prefix="€ "
                            value={secondInputRevenue}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale
                            label={signMap.get(signRevenue)}
                            error={targetListState.validateNewTargetList.errors.revenueSecond}
                            supportingText={targetListState.validateNewTargetList.errors.revenueSecond ? "Inserire il fatturato" : ''}
                            customInput={Input}
                            id={"company-to-confirm-price"}
                            key={"company-to-confirm-price"}
                            placeholder={signMap.get(signRevenue)}
                            onValueChange={(e) => {
                                setSecondInputRevenue(e.floatValue)
                            }}
                        />
                    </div>
                }
            </div>
            <div className="flex gap-4 w-full">
                <div style={{ width: 'calc((100% - 32px) / 3)' }}>
                    <SelectCustom
                        isClearable
                        placeholder={"Inserisci range del numero di dipendenti"}
                        options={[
                            { label: 'Uguale a', value: '=' },
                            { label: 'Minore di', value: '<' },
                            { label: 'Maggiore di', value: '>' },
                            { label: 'Compreso tra', value: '><' },
                        ]}
                        label="Numero di dipendenti"
                        error={targetListState.validateNewTargetList.errors.employeesSign}
                        errorLabel="Selezionare il tipo di numero dipendenti."
                        defaultValue={signEmployeesNumber}
                        onChange={e => setSignEmployeesNumber(e as "=" | "<" | ">" | "><" | undefined)}
                    />
                </div>
                {
                    signEmployeesNumber !== undefined &&
                    <div style={{ width: 'calc((100% - 32px) / 3)' }}>
                        <NumericFormat
                            value={firstInputEmployeesNumber}
                            thousandSeparator="."
                            decimalSeparator=","
                            label={signMap.get(signEmployeesNumber)}
                            error={targetListState.validateNewTargetList.errors.employeesFirst}
                            supportingText={targetListState.validateNewTargetList.errors.employeesFirst ? "Inserire il numero di dipendenti" : ''} customInput={Input}
                            id={"company-to-confirm-price"}
                            key={"company-to-confirm-price"}
                            placeholder={signMap.get(signEmployeesNumber)}
                            onValueChange={(e) => {
                                setFirstInputEmployeesNumber(e.floatValue)
                            }}
                        />
                    </div>
                }
                {
                    signEmployeesNumber === '><' &&
                    <div style={{ width: 'calc((100% - 32px) / 3)' }}>
                        <NumericFormat
                            value={secondInputEmployeesNumber}
                            thousandSeparator="."
                            decimalSeparator=","
                            label={signMap.get(signEmployeesNumber)}
                            error={targetListState.validateNewTargetList.errors.employeesSecond}
                            supportingText={targetListState.validateNewTargetList.errors.employeesSecond ? "Inserire il numero di dipendenti" : ''} customInput={Input}
                            id={"company-to-confirm-price"}
                            key={"company-to-confirm-price"}
                            placeholder={signMap.get(signEmployeesNumber)}
                            onValueChange={(e) => {
                                setSecondInputEmployeesNumber(e.floatValue)
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}