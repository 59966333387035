import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { TabMenu } from "../../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../../ui/organisms/tabs/tabPages";
import { newTargetList, setValidateNewTargetListStatus, validateNewTargetList } from "../../../targetList/slice";
import { Form } from "./form";
import { setEditProjectPage } from "../../slice";
import { EditProjectPage } from "../../dto";

export function AddTargetList() {
    const dispatch = useAppDispatch()

    const targetListState = useAppSelector(state => state.targetList)

    const [direction, setDirection] = useState<'left' | 'right'>('left');
    const [tabValue, setTabValue] = useState<number>(0)

    useEffect(() => {
        if (targetListState.validateNewTargetList.status === 'successfully') {
            dispatch(newTargetList(targetListState.newTargetListRequest))
            dispatch(setValidateNewTargetListStatus('idle'))
        }
    }, [targetListState.validateNewTargetList.status])

    return (
        <div className="flex flex-col bg-white rounded-2xl shadow-sm m-6">
            <div className="p-4 flex justify-between items-center">
                <div className="flex items-center gap-6">
                    <span className="text-heading-xs font-semibold text-neutral-800">Nuova lista target</span>
                    <TabMenu
                        direction={direction}
                        setDirection={value => setDirection(value)}
                        setValue={e => {
                            setTabValue(e)
                        }}
                        value={tabValue}
                        items={[
                            {
                                icon: <></>,
                                label: "Specifiche lista"
                            },
                            {
                                icon: <></>,
                                label: "Persone (0)",
                                disabled: true
                            },
                            {
                                icon: <></>,
                                label: "Aziende (0)",
                                disabled: true
                            },
                        ]}
                    />
                </div>
                {
                    targetListState.newTargetListStatus === 'loading' ||
                        targetListState.validateNewTargetList.status === 'loading'
                        ?
                        <Spinner /> :
                        <Button size={"sm"} iconPosition={"left"} variant={"outline"} color={"blue"} label="Salva lista" onClick={() => dispatch(validateNewTargetList(targetListState.newTargetListRequest))} />
                }
            </div>
            <TabPages
                direction={direction}
                setDirection={value => setDirection(value)}
                pages={[
                    <div key={0} className="max-h-full"><Form /></div>,
                    <div key={1} className="h-full"></div>,
                    <div key={2} className="h-full"></div>,
                ]}
                value={tabValue}
                setValue={(e) => { setTabValue(e); }}
            />
        </div>
    )
}