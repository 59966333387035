import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import CompaniesReducer from '../../pages/database/companies/slice';
import PeopleReducer from '../../pages/database/people/slice';
import DatabaseReducer from '../../pages/database/slice';
import ProjectsReducer from '../../pages/projects/slice';
import TargetListReducer from '../../pages/targetList/slice';
import UsersReducer from '../../pages/users/slice';
import LayoutReducer from '../../ui/layout/slice';
import KeycloakReducer from '../keycloak/slice';
import PreferencesReducer from '../preferences/slice';

export const store = configureStore({
    reducer: {
        keycloak: KeycloakReducer,
        layout: LayoutReducer,
        users: UsersReducer,
        database: DatabaseReducer,
        people: PeopleReducer,
        companies: CompaniesReducer,
        preferences: PreferencesReducer,
        projects: ProjectsReducer,
        targetList: TargetListReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

