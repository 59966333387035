import clsx from "clsx";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../lib/redux/hooks";
import { SortIcon } from "../../../../../../ui/icons/sort";
import Avatar from "../../../../../../ui/molecules/avatar";
import Pills from "../../../../../../ui/molecules/pills";
import { Pagination } from "../../../../../../ui/organisms/pagination";
import { targertListsStatePillMap } from "../../../../../targetList/dto";
import {
  setTargetListsFilterOrder,
  setTargetListsFilterPage,
  setTargetListsFilterSort,
} from "../../../../../targetList/slice";

interface Props {
  setStep: (value: "target" | "contact") => void;
  setTarget: (value: { id: string; name: string } | undefined) => void;
}
export function ImportFromTargetListList(props: Props) {
  const { setStep, setTarget } = props;
  const targetListsState = useAppSelector((state) => state.targetList);
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  return (
    <div className="w-[100%] flex flex-col">
      <div>
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Nome lista target
                  <div
                    onClick={() => {
                      dispatch(setTargetListsFilterSort("name"));
                      dispatch(
                        setTargetListsFilterOrder(
                          !targetListsState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Team Leader
                  <div
                    onClick={() => {
                      dispatch(setTargetListsFilterSort("project.teamLeader"));
                      dispatch(
                        setTargetListsFilterOrder(
                          !targetListsState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Commerciali
                  <div
                    onClick={() => {
                      dispatch(setTargetListsFilterSort("commercials.name"));
                      dispatch(
                        setTargetListsFilterOrder(
                          !targetListsState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Settore
                  <div
                    onClick={() => {
                      dispatch(setTargetListsFilterSort("sector"));
                      dispatch(
                        setTargetListsFilterOrder(
                          !targetListsState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Contatti
                  <div
                    onClick={() => {
                      dispatch(setTargetListsFilterSort("contacts"));
                      dispatch(
                        setTargetListsFilterOrder(
                          !targetListsState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
              <th className="text-left pr-4">
                <div className="flex gap-[4px] px-[4px] py-3">
                  Stato
                  <div
                    onClick={() => {
                      dispatch(setTargetListsFilterSort("state"));
                      dispatch(
                        setTargetListsFilterOrder(
                          !targetListsState.filters.order
                        )
                      );
                    }}
                    className="cursor-pointer hover: opacity-70"
                  >
                    <SortIcon />
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {targetListsState.findAllTargetListsResponse?.data.map((target) => (
              <tr
                onClick={() => {
                  if (
                    target.id !== targetListsState.findTargetListResponse?.id
                  ) {
                    setTarget({ id: target.id, name: target.name });
                    setStep("contact");
                  }
                }}
                className={clsx(
                  "h-[56px] border-b-[1px] border-b-neutral-100",
                  {
                    "bg-neutral-300 opacity-40":
                      target.id === targetListsState.findTargetListResponse?.id,
                    "bg-white cursor-pointer  hover:bg-brandPrimary-100":
                      target.id !== targetListsState.findTargetListResponse?.id,
                  }
                )}
                key={target.id}
              >
                <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                  {target.name}
                </td>
                <td className="px-[4px] text-text-md font-semibold text-neutral-600">
                  <Avatar
                    type="single"
                    size="sm"
                    shape="circle"
                    imageUrl={
                      usersState.findUsersAvatarsListResponse.find(
                        (avatar) =>
                          avatar.userId === target.project.teamLeader.id
                      )?.objectId ?? ""
                    }
                    altTextInitials={
                      target.project.teamLeader.name[0] +
                      " " +
                      target.project.teamLeader.surname[0]
                    }
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Avatar
                    type="group"
                    size="sm"
                    length={2}
                    imageUrls={target.commercials.map((commercial) => ({
                      altTextInitials:
                        commercial.name[0] + commercial.surname[0],
                      url:
                        usersState.findUsersAvatarsListResponse.find(
                          (avatar) => avatar.userId === commercial.id
                        )?.objectId ?? "",
                    }))}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={
                      target.sectors[0] +
                      (target.sectors.length > 1
                        ? " +" + (target.sectors.length - 1)
                        : "")
                    }
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={target.contacts?.toString() ?? "0"}
                    outline={false}
                    emphasis={false}
                    color={"blue"}
                  />
                </td>
                <td className="px-[4px] text-text-sm text-neutral-600">
                  <Pills
                    size={"xs"}
                    label={targertListsStatePillMap.get(target.state)?.label!}
                    outline={false}
                    emphasis={false}
                    color={targertListsStatePillMap.get(target.state)?.color!}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          found={targetListsState.findAllTargetListsResponse?.total || 0}
          pages={targetListsState.findAllTargetListsResponse?.totalPage || 1}
          currentPage={targetListsState.filters.page}
          setPage={(page) => dispatch(setTargetListsFilterPage(page))}
        />
      </div>
    </div>
  );
}
