import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import { Spinner } from "../../ui/molecules/spinner";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { NotFound } from "../../ui/organisms/notFound";
import {
  findCommercials,
  findTeamLeaders,
  findUsersAvatarsList,
} from "../users/slice";
import { TargetListsFilters } from "./filters";
import { TargetListsList } from "./list";
import {
  findAllTArgetLists,
  setDeleteTargetListStatus,
  setSelectedTargetList,
  setTargetListsFilterPage,
} from "./slice";

export function TargetLists() {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const targetListsState = useAppSelector((state) => state.targetList);
  const usersState = useAppSelector((state) => state.users);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    dispatch(findAllTArgetLists(targetListsState.filters));
    dispatch(findTeamLeaders());
    dispatch(findCommercials());
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (
      usersState.findTeamLeadersStatus === "successfully" &&
      usersState.findCommercialsStatus === "successfully"
    ) {
      const teamLeaderAvatars = usersState.findTeamLeadersResponse?.data.map(
        (teamLeader) => ({
          userId: teamLeader.id,
          objectId: teamLeader.avatarObjectId,
        })
      );
      const commercialAvatars = usersState.findCommercialsResponse?.data.map(
        (commercial) => ({
          userId: commercial.id,
          objectId: commercial.avatarObjectId,
        })
      );

      dispatch(
        findUsersAvatarsList(
          (teamLeaderAvatars || []).concat(commercialAvatars || [])
        )
      );
    }
  }, [usersState.findTeamLeadersStatus, usersState.findCommercialsStatus]);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(setTargetListsFilterPage(0));
      dispatch(findAllTArgetLists({ ...targetListsState.filters, page: 0 }));
    }
  }, [
    targetListsState.filters.order,
    targetListsState.filters.sort,
    targetListsState.filters.name,
    targetListsState.filters.teamLeader,
    targetListsState.filters.commercial,
    targetListsState.filters.sector,
    targetListsState.filters.state,
  ]);

  useEffect(() => {
    if (!isFirstLoad) {
    }
    dispatch(findAllTArgetLists(targetListsState.filters));
  }, [targetListsState.filters.page]);

  useEffect(() => {
    if (targetListsState.selectedTargetList !== undefined) {
      navigation("/edit-target-list/" + targetListsState.selectedTargetList);
      dispatch(setSelectedTargetList(undefined));
    }
  }, [targetListsState.selectedTargetList]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListsState.deleteTargetListStatus === "failed" ||
      targetListsState.deleteTargetListStatus === "successfully"
    ) {
      if (targetListsState.deleteTargetListStatus === "successfully") {
        dispatch(findAllTArgetLists(targetListsState.filters));
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteTargetListStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListsState.deleteTargetListStatus]);

  return (
    <Layout
      menuItem={MenuItems.TARGET_LIST}
      breadcrumbItems={["Liste Target"]}
      headerLabel="Liste Target"
    >
      <div className="p-6">
        <div className="absolute flex flex-col gap-4 right-6">
          <Banner
            label={"Lista target eliminata."}
            visible={targetListsState.deleteTargetListStatus === "successfully"}
            closeAction={function (): void {
              dispatch(setDeleteTargetListStatus("idle"));
            }}
            type={"success"}
          />
          <Banner
            label={
              "Si è verificato un errore durante l'eliminazione della lista target."
            }
            visible={targetListsState.deleteTargetListStatus === "failed"}
            closeAction={function (): void {
              dispatch(setDeleteTargetListStatus("idle"));
            }}
            type={"error"}
          />
        </div>
        <div className="flex flex-col bg-white rounded-2xl shadow-sm">
          <div className="p-4">
            <span className="text-heading-xs font-semibold text-neutral-800">
              Overview
            </span>
          </div>
          <TargetListsFilters />
          {targetListsState.findAllTargetListsStatus === "loading" ||
          usersState.findTeamLeadersStatus === "loading" ||
          usersState.findCollaboratorsStatus === "loading" ? (
            <div className="w-full h-[50%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : targetListsState.findAllTargetListsResponse?.data.length === 0 ||
            targetListsState.findAllTargetListsResponse?.data === null ? (
            <NotFound />
          ) : (
            <TargetListsList />
          )}
        </div>
      </div>
    </Layout>
  );
}
