import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { ArrowRightIcon } from "../../../../ui/icons/arrowRight";
import { UploadIcon } from "../../../../ui/icons/upload";
import Button from "../../../../ui/molecules/button";
import { SelectCustom } from "../../../../ui/molecules/select";
import { Spinner } from "../../../../ui/molecules/spinner";
import { PeopleHeader } from "../dto";
import { importCSV } from "../slice";

interface Props {
  step: number;
  setStep: (value: number) => void;
  name: string;
  delimiter: string;
  fields: string[];
  file: File | null;
  rows: Record<string, any>[];
}
export function Step2(props: Props) {
  const dispatch = useAppDispatch();
  const peopleState = useAppSelector((state) => state.people);

  const [headers, setHeaders] = useState<PeopleHeader>({
    name: [],
    surname: [],
    role: [],
    department: [],
    phone: [],
    email: [],
    linkedinProfile: [],
    privacy: [],
    companyName: [],
    companyVat: [],
    companyPhone: [],
  });

  const options = props.fields.filter(
    (field) =>
      !headers.name.includes(field) &&
      !headers.surname.includes(field) &&
      !headers.role.includes(field) &&
      !headers.department.includes(field) &&
      !headers.phone.includes(field) &&
      !headers.email.includes(field) &&
      !headers.linkedinProfile.includes(field) &&
      !headers.privacy.includes(field) &&
      !headers.companyName.includes(field) &&
      !headers.companyVat.includes(field) &&
      !headers.companyPhone.includes(field)
  );

  const formData = new FormData();

  return (
    <div>
      <div className="p-4 border-y border-y-neutral-100 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span
            onClick={() => props.setStep(0)}
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
          >
            Aggiungi da file CSV
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span className="text-text-md font-bold text-neutral-800">
            Mappatura campi
          </span>
        </div>
        {peopleState.importCSVStatus === "loading" ? (
          <Spinner />
        ) : (
          <Button
            onClick={() => {
              const filteredHeaders = Object.fromEntries(
                Object.entries(headers).filter(
                  ([key, value]) => Array.isArray(value) && value.length > 0
                )
              );
              formData.delete("file");
              formData.delete("headers");
              formData.delete("delimiter");
              if (props.file !== null) formData.append("file", props.file);
              formData.append("headers", JSON.stringify(filteredHeaders));
              formData.append("delimiter", props.delimiter);
              dispatch(importCSV(formData));
            }}
            disabled={!props.file}
            size={"sm"}
            iconPosition={"right"}
            variant={"outline"}
            color={"blue"}
            label="Importa contatti"
            icon={<UploadIcon color={""} size={0} />}
          />
        )}
      </div>
      <div className="p-4">
        <span className="text-text-md font-bold text-neutral-800">
          Anteprima file CSV
        </span>
      </div>
      <div>
        <table className="w-full" align="left">
          <thead>
            <tr className="bg-neutral-100">
              {props.fields.map((field) => (
                <th
                  align="left"
                  className="first:pl-4 last:pr-4 h-10 text-label-sm font-semibold text-neutral-800"
                >
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.rows.slice(0, 3).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {props.fields.map((field) => (
                  <td
                    key={field}
                    className="first:pl-4 last:pr-4 h-10 text-label-sm text-neutral-700"
                  >
                    {row[field] || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-brandPrimary-100 rounded-b-xl p-4 gap-6 flex flex-col items-start overflow-auto">
        <span className="text-text-md font-bold text-neutral-800">
          Mappa campi CRM
        </span>
        <div className="flex w-full gap-4">
          <SelectCustom
            label="Nome"
            defaultValue={headers.name[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.name = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.name = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il nome"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Cognome"
            defaultValue={headers.surname[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.surname = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.surname = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il cognome"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Ruolo"
            defaultValue={headers.role[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.role = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.role = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il ruolo"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            label="Settore"
            defaultValue={headers.department[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.department = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.department = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il settore"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Smartphone"
            defaultValue={headers.phone[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.phone = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.phone = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il cellulare"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Email"
            defaultValue={headers.email[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.email = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.email = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per l'email"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            label="Profilo linkedin"
            defaultValue={headers.linkedinProfile[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.linkedinProfile = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.linkedinProfile = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il profilo linkedin"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Privacy"
            defaultValue={headers.privacy[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.privacy = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.privacy = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la privacy"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Nome azienda"
            defaultValue={headers.companyName[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.companyName = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.companyName = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il nome dell'azienda"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            label="P. IVA azienda"
            defaultValue={headers.companyVat[0]}
            isClearable
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.companyVat = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.companyVat = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la P.IVA azienda"}
            options={options.map((field) => ({
              label: field,
              value: field,
            }))}
          />
          <SelectCustom
            label="Telefono azienda"
            defaultValue={headers.companyPhone}
            isClearable
            isMulti
            onChange={(e) => {
              if (e === undefined || e.length === 0) {
                const newHeaders = { ...headers };
                newHeaders.companyPhone = [];
                setHeaders(newHeaders);
              } else if (e.length > 0 && typeof e !== "string") {
                const newHeaders = { ...headers };
                newHeaders.companyPhone = [...e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il telefono dell'azienda"}
            options={options
              .map((field) => ({ label: field, value: field }))
              .concat(
                headers.companyPhone.map((phone) => ({
                  label: phone,
                  value: phone,
                }))
              )}
          />
        </div>
      </div>
    </div>
  );
}
