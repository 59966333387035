import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../lib/redux/hooks";
import { Spinner } from "../../../../../../ui/molecules/spinner";
import { NotFound } from "../../../../../../ui/organisms/notFound";
import { TargetListsFilters } from "../../../../../targetList/filters";
import { findAllTArgetLists } from "../../../../../targetList/slice";
import { ImportFromTargetListList } from "./list";

interface Props {
  setStep: (value: "target" | "contact") => void;
  setTarget: (value: { id: string; name: string } | undefined) => void;
}
export function ImportFromTargetTargetList(props: Props) {
  const { setStep, setTarget } = props;
  const dispatch = useAppDispatch();

  const targetListsState = useAppSelector((state) => state.targetList);
  const usersState = useAppSelector((state) => state.users);

  useEffect(() => {
    dispatch(findAllTArgetLists(targetListsState.filters));
  }, [targetListsState.filters.page]);

  return (
    <div className="flex max-h-full w-full">
      <div className="flex flex-col bg-white w-full">
        <div className="p-4">
          <span className="text-heading-xs font-semibold text-neutral-800">
            Liste target
          </span>
        </div>
        <TargetListsFilters />
        {targetListsState.findAllTargetListsStatus === "loading" ||
        usersState.findTeamLeadersStatus === "loading" ||
        usersState.findCollaboratorsStatus === "loading" ? (
          <div className="w-full h-[50%] flex items-center justify-center">
            <Spinner />
          </div>
        ) : targetListsState.findAllTargetListsResponse?.data.length === 0 ||
          targetListsState.findAllTargetListsResponse?.data === null ? (
          <NotFound />
        ) : (
          <ImportFromTargetListList
            setStep={(value) => setStep(value)}
            setTarget={function (
              value: { id: string; name: string } | undefined
            ): void {
              setTarget(value);
            }}
          />
        )}
      </div>
    </div>
  );
}
