import clsx from "clsx";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../lib/redux/hooks";
import { colors } from "../../../../../../ui/colors";
import { CheckCircleIcon } from "../../../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../../../ui/icons/circle";
import { LinkedinIcon } from "../../../../../../ui/icons/linkedin";
import Checkbox from "../../../../../../ui/molecules/checkbox";
import { Pagination } from "../../../../../../ui/organisms/pagination";
import { setFindAllPeopleFiltersPage } from "../../../../../database/people/slice";

interface Props {
  selectedIds: string[];
  setSelectedIds: (values: string[]) => void;
}
export function ImportFromDatabaseList(props: Props) {
  const { selectedIds, setSelectedIds } = props;
  const peopleState = useAppSelector((state) => state.people);
  const targetListState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  const isAlreadyIn = (id: string): boolean => {
    return targetListState.findTargetListResponse?.personContacts &&
      targetListState.findTargetListResponse?.personContacts[id] !== undefined
      ? true
      : false;
  };

  return (
    <div className="w-full">
      <div className="overflow-auto">
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs">
            <tr className="bg-neutral-50">
              <th className="text-left pl-4 w-10">
                <Checkbox
                  shape={"square"}
                  disabled={
                    peopleState.findAllResponse?.data.every((person) =>
                      isAlreadyIn(person.id)
                    )!
                  }
                  checked={
                    peopleState.findAllResponse?.data
                      .filter((person) => !isAlreadyIn(person.id))
                      .every((person) => selectedIds.includes(person.id))! ||
                    peopleState.findAllResponse?.data.every((person) =>
                      isAlreadyIn(person.id)
                    )!
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      peopleState.findAllResponse?.data
                        .filter((person) => !isAlreadyIn(person.id))
                        .every((person) => selectedIds.includes(person.id))
                    ) {
                      let newSelected = [...selectedIds];
                      peopleState.findAllResponse?.data.forEach((person) => {
                        newSelected = newSelected.filter(
                          (id) => person.id !== id
                        );
                      });
                      setSelectedIds([...newSelected]);
                    } else {
                      setSelectedIds([
                        ...selectedIds,
                        ...peopleState.findAllResponse?.data
                          .filter(
                            (person) =>
                              !selectedIds.includes(person.id) &&
                              !isAlreadyIn(person.id)
                          )
                          .map((person) => person.id)!,
                      ]);
                    }
                  }}
                />
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">Cognome</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">nome</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">smartphone</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">email</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">
                  <LinkedinIcon color={colors.neutral[400]} size={16} />
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">ruolo</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px] px-[4px] py-3">reparto</div>
              </th>
              <th className="text-left  pr-4">
                <div className="flex gap-[4px] px-[4px] py-3">privacy</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {peopleState.findAllResponse?.data.map((person) => (
              <tr
                className={clsx(
                  "h-[56px] border-b-[1px] border-b-neutral-100",
                  {
                    "bg-brandPrimary-100": selectedIds.includes(person.id),
                    "bg-white":
                      !selectedIds.includes(person.id) &&
                      !isAlreadyIn(person.id),
                    "bg-neutral-200 opacity-70":
                      !selectedIds.includes(person.id) &&
                      isAlreadyIn(person.id),
                  }
                )}
                key={person.id}
              >
                <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                  <Checkbox
                    shape={"square"}
                    disabled={isAlreadyIn(person.id)}
                    checked={
                      selectedIds.includes(person.id) || isAlreadyIn(person.id)
                    }
                    onCheck={function (value: boolean): void {
                      if (selectedIds.includes(person.id)) {
                        setSelectedIds([
                          ...selectedIds.filter((id) => id !== person.id),
                        ]);
                      } else {
                        setSelectedIds([...selectedIds, person.id]);
                      }
                    }}
                  />
                </td>
                <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
                  {person.surname ?? "-"}
                </td>
                <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
                  {person.name ?? "-"}
                </td>
                <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                  {person.phone ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"tel:" + person.phone}
                      className="text-text-md font-semibold text-neutral-600"
                    >
                      {person.phone}
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                  {person.email ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"mailto:" + person.email}
                      className="text-text-md font-semibold text-neutral-600"
                    >
                      {person.email}
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={person.linkedinProfile ?? undefined}
                    className="w-full flex"
                  >
                    <LinkedinIcon
                      color={
                        person.linkedinProfile
                          ? colors.neutral[400]
                          : colors.neutral[200]
                      }
                      size={20}
                    />
                  </a>
                </td>
                <td className="px-[4px] min-w-[200px] text-text-md font-semibold text-neutral-600">
                  {person.role ?? "-"}
                </td>
                <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-brandPrimary-600">
                  {person.companyName ?? "-"}
                </td>
                <td className="px-[4px] min-w-[150px] text-text-md font-semibold text-neutral-600">
                  {person.department ?? "-"}
                </td>
                <td className="px-[4px] min-w-[100px] text-text-md font-semibold text-neutral-600">
                  <div className="w-full flex justify-center">
                    {person.privacy ? (
                      <CheckCircleIcon color={colors.neutral[400]} size={20} />
                    ) : (
                      <CircleIcon color={colors.neutral[400]} size={20} />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        found={peopleState.findAllResponse?.total || 0}
        key={"pagination-people-bottom"}
        pages={peopleState.findAllResponse?.totalPage || 0}
        currentPage={peopleState.findAllResponse?.page || 0}
        setPage={(page) => {
          dispatch(setFindAllPeopleFiltersPage(page));
        }}
      />
    </div>
  );
}
